import React, { useState, useEffect } from "react";
import { Card, FormControl, Flex } from "@chakra-ui/react";
import SelectField from "components/fields/SelectField";
import MultiSelect from "components/fields/MultiSelect";
import TextField from "components/fields/TextField";
import InputField from "components/fields/InputField";
import { useDispatch, useSelector } from "react-redux";
import { setLeaveType, setLeaveCount, setSelectedEmployees, setReasonNote, setLeaveYear, closeForm } from "../reducers/leavesQuotaReducers";
import { getLeaveTypes, submitAdditionalLeave } from "../api/leavesQuota";
import customToast from "utils/toastUtils";
import { MdMinimize } from "react-icons/md";
import { fetchAllEmployees } from "views/user/manageLeaves/api/manageLeaves";

const AdditionalLeaveForm = () => {
  const toast = customToast();
  const dispatch = useDispatch();
  const { leaveType, leaveCount, selectedEmployees, reasonNote, leaveYear } = useSelector((state) => state.leavesQuota);
  const [leaveTypeOptions, setLeaveTypeOptions] = useState([]);
  const [employeeOptions, setEmployeeOptions] = useState([]);

  const fetchLeaveTypes = async () => {
    const types = await getLeaveTypes();
    setLeaveTypeOptions(types || []);
  };

  const fetchEmployees = async () => {
    try {
      const { data: employees } = await fetchAllEmployees();
      setEmployeeOptions(employees?.map(emp => ({ id: emp.employee_id, name: emp.employee_name })) || []);
    } catch (error) {
      console.error("Error fetching employees:", error);
      toast.showToast({
        title: 'Error fetching employees',
        description: 'Could not fetch employees, please try again later.',
        status: 'error',
      });
    }
  };

  useEffect(() => {
    fetchLeaveTypes();
    fetchEmployees();
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedEmployees.length === 0) {
      toast.showToast({
        title: 'Error',
        description: 'Please select at least one employee.',
        status: 'error',
      });
      return;
    }

    try {
      await submitAdditionalLeave({ leaveType, leaveCount, selectedEmployees, reasonNote, leaveYear });
      toast.showToast({
        title: 'Success',
        description: 'Additional leave(s) added successfully!',
        status: 'success',
      });

      dispatch(closeForm());

    } catch (err) {
      toast.showToast({
        title: 'Error',
        description: 'Failed to submit form. Please try again.',
        status: 'error',
      });
    }
  };

  return (
    <Card className="bg-white dark:!bg-navy-800 px-4 pb-2 dark:shadow-none dark:text-lightPrimary mt-8">
      <div className="flex mb-3 mx-[1rem] h-full items-center justify-between mt-6">
        <div className="text-xl font-bold mt-4 mb-4">Additional Leave(s)</div>
        <button
          onClick={() => dispatch(closeForm())}
          className="text-xl text-brand-500 hover:text-brand-600 active:text-brand-700"
        >
          <MdMinimize size={30} />
        </button>
      </div>

      <form onSubmit={handleSubmit} className="mx-4">
        <Flex wrap="wrap" gap="4" mb={4} alignItems="center">
          <FormControl isRequired flex="1">
            <SelectField
              label="Leave Type"
              placeholder="Select Leave Type"
              value={leaveType}
              onChange={(e) => dispatch(setLeaveType(e.target.value))}
              options={leaveTypeOptions.length ? leaveTypeOptions : []}
            />
          </FormControl>

          <FormControl isRequired flex="1">
            <InputField
              type="number"
              label="Leave Count"
              placeholder="Enter leave count"
              id="leave_count"
              value={leaveCount}
              onChange={(e) => dispatch(setLeaveCount(e.target.value))}
              required
            />
          </FormControl>

          <FormControl isRequired flex="1">
            <SelectField
              label="Leave Year"
              value={leaveYear}
              onChange={(e) => dispatch(setLeaveYear(e.target.value))}
              options={[...Array(5)].map((_, index) => {
                const year = new Date().getFullYear() - index;
                return {
                  id: year,
                  value: year,
                };
              })}
              placeholder="Select Leave Year"
              required
            />
          </FormControl>
        </Flex>

        <FormControl isRequired mb={4}>
          <MultiSelect
            options={employeeOptions}
            formFieldName="Select employees"
            label="Employees"
            initialVal={selectedEmployees}
            onChange={(val) => dispatch(setSelectedEmployees(val))}
          />
        </FormControl>

        <FormControl mb={4}>
          <TextField
            label="Reason"
            value={reasonNote}
            onChange={(e) => dispatch(setReasonNote(e.target.value))}
            placeholder="Enter leave reason"
          />
        </FormControl>

        <Flex justify="end" mt={4}>
          <button
            type="submit"
            className="float-right mb-3 mr-3 mt-2 rounded-md bg-brand-500 px-5 py-2 text-base font-medium text-white shadow-sm hover:bg-brand-600"
          >
            Save
          </button>
        </Flex>
      </form>
    </Card>
  );
};

export default AdditionalLeaveForm;
