import React from "react";
import { MdCancel, MdFileUpload } from "react-icons/md";
import { FormLabel } from '@chakra-ui/react';
import { LEAVE_ATTACHMENTS_STORAGE } from "variables/common";

const FileUpload = (props) => {
  const {
    label, id, variant, files, setFiles, placeholderText, subLabel, size = "md"
  } = props;

  const handleFileChange = async (event) => {
    const addedFiles = event.target.files;
    // if (addedFiles?.length>0 && addedFiles?.some(file => file.size > 1024 * 1024)) {
    //   toast.showToast({
    //     title: 'Invalid Data',
    //     description: 'No file size should exceed 1MB. Please try again.',
    //     status: 'warning',
    //   })
    //   return;
    // }
    // else 
    setFiles([...files, ...addedFiles])
    // Perform further processing or upload the file
  };

  const handleFileCancel = (e, idx) => {
    e.stopPropagation();
    e.preventDefault();
    const copyData = [...files];
    copyData.splice(idx, 1);
    setFiles(copyData);
  };

  // Define size classes based on the size prop
  const sizeClasses = {
    sm: {
      container: "h-16 w-full",
      icon: "text-[40px]",
      text: "text-sm",
    },
    md: {
      container: "h-24 w-full",
      icon: "text-[60px]",
      text: "text-md",
    },
    lg: {
      container: "h-32 w-full",
      icon: "text-[80px]",
      text: "text-lg",
    },
  };

  const { container, icon, text } = sizeClasses[size];

  return (
    <div>
      <FormLabel
        htmlFor={id}
        className={`!text-sm text-navy-700 dark:text-white ${subLabel ? '!mb-0' : ''} ${variant === "auth" ? "ml-1.5 font-medium" : "font-bold"}`}
      >
        {label}
      </FormLabel>
      {subLabel && (
        <p className="mb-2 text-sm text-gray-500">
          {subLabel}
        </p>
      )}
      <label htmlFor="file-upload" className={`col-span-5 cursor-pointer rounded-xl bg-lightPrimary dark:!bg-navy-700 2xl:col-span-6 ${container}`}>
        <div className={`flex h-full w-full flex-col items-center justify-center rounded-xl border-[2px] border-dashed border-gray-200 py-3 dark:!border-navy-700 lg:pb-0`}>
          <MdFileUpload className={`text-brand-500 dark:text-white ${icon}`} />
          <h4 className={`text-center font-bold text-brand-500 dark:text-white ${text}`}>
            Upload Files
          </h4>
          {(!files || files.length === 0) && <p className={`mt-2 font-medium text-gray-600 max-w-[60%] text-center ${text}`}>
            {placeholderText ?? "doc, docx, pdf, png and jpg files are allowed"}
          </p>}
          {files && files.length > 0 &&
            <div className="flex gap-2 mb-3 max-w-[75%] flex-wrap">
              {files.map((file, index) => {
                return (
                  <p key={file.name} className={`mt-2 font-medium px-2 py-1 rounded-md bg-gray-600 flex gap-4 items-center space-between ${text}`}>
                    <span className="cursor-default">{file.name.startsWith(`${LEAVE_ATTACHMENTS_STORAGE}/`) ? file.name.split('/').slice(-1) : file.name}</span>
                    <MdCancel className="cursor-pointer" onClick={(e) => handleFileCancel(e, index)} fontSize={18} />
                  </p>
                )
              })}
            </div>
          }
          <p className={`mb-2 font-medium text-brand-500 max-w-[60%] text-center ${text}`}>
            Attached files will be visible to your reporting manager and HR manager.</p>
        </div>
      </label>
      <input type="file" multiple id='file-upload' style={{ display: "none" }} onChange={handleFileChange} />
    </div>
  );
}

export default FileUpload;
