import React from "react";
import Dropdown from "components/dropdown";
import { FiAlignJustify } from "react-icons/fi";
import { Link } from "react-router-dom";
import { RiMoonFill, RiSunFill } from "react-icons/ri";
import avatar from "assets/img/avatars/avatar.png";
import { useDispatch, useSelector } from "react-redux";
import { setSession } from "layouts/reducers/authReducers";
import { supabase } from "utils/supabase";
import { setDarkMode } from "layouts/reducers/layoutReducers";

const Navbar = (props) => {
  const { onOpenSidenav, brandText } = props;
  const { darkMode } = useSelector((state) => state.layout);
  const { employee, session } = useSelector((state) => state.auth);
  const dispatch = useDispatch()

  return (
    <nav className="sticky top-4 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-4 backdrop-blur-xl dark:bg-[#0b14374d]">
      <div className="ml-[6px]">
        {/* <div className="h-6 w-[224px] pt-1">
          {brandText && (
            <>
              <a
                className="text-sm font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white"
                href=" "
              > pages
                <span className="mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white">
                  {" "}
                  /{" "}
                </span>
              </a>
              <Link
                className="text-sm font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white"
                to="#"
              >
                {brandText}
              </Link>
            </>
          )}
        </div> */}
        <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
          <Link
            to="#"
            className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
          >
            {brandText}
          </Link>
        </p>
      </div>

      <div className="relative mt-[3px] flex h-[61px] w-[250px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[265px] md:flex-grow-0 md:gap-1 xl:w-[265px] xl:gap-2">
        <span
          className="flex cursor-pointer text-xl text-gray-600 dark:text-white"
          onClick={onOpenSidenav}
        >
          <FiAlignJustify className="h-5 w-5" />
        </span>

        <div
          className="cursor-pointer text-gray-600"
          onClick={() => {
            if (darkMode) {
              document.body.classList.remove("dark");
              dispatch(setDarkMode(false));
            } else {
              document.body.classList.add("dark");
              dispatch(setDarkMode(true));
            }
          }}
        >
          {darkMode ? (
            <RiSunFill className="h-4 w-4 text-gray-600 dark:text-white" />
          ) : (
            <RiMoonFill className="h-4 w-4 text-gray-600 dark:text-white" />
          )}
        </div>
        {/* Profile & Dropdown */}
        <Dropdown
          button={
            <img
              className="h-10 w-10 rounded-full"
              src={session?.user?.user_metadata?.picture ?? avatar}
              onError={(e) => {
                e.currentTarget.onerror = null;
                e.currentTarget.src = avatar;
              }}
              alt="Avatar"
            />
          }
          children={
            <div className="flex w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div className="p-4">
                <div className="flex items-center gap-2">
                  <p className="text-md font-bold text-navy-700 dark:text-white">
                    {`👋 Hey, ${session?.user?.user_metadata?.full_name ?? "Username"}`}
                  </p>{" "}
                </div>
              </div>
              <div className="h-px w-full bg-gray-200 dark:bg-white/20 " />

              <div className="flex flex-col p-2">
                <button
                  className="capitalize px-4 text-left cursor-default text-sm text-gray-800 dark:text-white hover:dark:text-white"
                >
                  {employee?.employee_role_name ?? "Employee"}
                </button>
                <button
                  onClick={() => {
                    supabase.auth.signOut();
                    dispatch(setSession(null));
                  }}
                  className="mt-3 text-sm font-medium px-4 text-left text-red-500 hover:text-red-500"
                >
                  Log Out
                </button>
              </div>
            </div>
          }
          classNames={"py-2 top-8 -left-[180px] w-max"}
        />
      </div>
    </nav>
  );
};

export default Navbar;
