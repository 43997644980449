import React, { useEffect, useState } from 'react';
import { FormControl, Flex } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addFamilyDetails,
  editFamilyDetails,
  fetchFamilyDetailsByEmployeeId,
  fetchEmployeeById,
} from '../api/manageFamily';
import InputField from 'components/fields/InputField';
import DatePicker from 'components/fields/DatePicker';
import customToast from 'utils/toastUtils';
import NoDataFound from './NoDataFound';
import Loader from 'components/loader/miniLoader';

const FamilyDetailsForm = ({ mode, employeeId }) => {
  const dispatch = useDispatch();
  const toast = customToast();

  const { newEmployeeData } = useSelector((state) => state.manageEmployees);

  const { familyDetails } = useSelector((state) => state.manageFamily);

  const [formState, setFormState] = useState({
    spouse_name: '',
    spouse_dob: null,
    number_of_children: 0,
    children: [],
  });

  const [isMarried, setIsMarried] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const empId = mode === 'Add' ? newEmployeeData?.employee_id : employeeId;

    if (empId) {
      dispatch(fetchEmployeeById(empId))
        .then((employee) => {
          const maritalStatus = employee?.maritial_status?.toLowerCase(); 
          if (maritalStatus === 'married') {
            setIsMarried(true);
          } else {
            setIsMarried(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });

      if (mode === 'Edit') {
        // Fetch family details only in Edit mode
        dispatch(fetchFamilyDetailsByEmployeeId(empId));
      }
    }
  }, [dispatch, mode, employeeId, newEmployeeData]);

  useEffect(() => {
    if (familyDetails && mode === 'Edit') {
      // Set family details only in Edit mode
      setFormState(familyDetails);
    } else if (mode === 'Add') {
      // Clear form state in Add mode
      setFormState({
        spouse_name: '',
        spouse_dob: null,
        number_of_children: 0,
        children: [],
      });
    }
  }, [familyDetails, mode]);

  const handleInputChange = (field, value) => {
    setFormState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleChildrenChange = (index, field, value) => {
    const updatedChildren = [...formState.children];
    updatedChildren[index] = {
      ...updatedChildren[index],
      [field]: value,
    };
    setFormState((prevState) => ({
      ...prevState,
      children: updatedChildren,
    }));
  };

  const handleNumberOfChildrenChange = (value) => {
    const numChildren = parseInt(value, 10) || 0;
    let updatedChildren = [...formState.children];

    if (numChildren > updatedChildren.length) {
      for (let i = updatedChildren.length; i < numChildren; i++) {
        updatedChildren.push({ name: '', dob: null });
      }
    } else if (numChildren < updatedChildren.length) {
      updatedChildren = updatedChildren.slice(0, numChildren);
    }

    setFormState((prevState) => ({
      ...prevState,
      number_of_children: numChildren,
      children: updatedChildren,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const empId = mode === 'Add' ? newEmployeeData?.employee_id : employeeId;

    if (!empId) {
      toast.showToast({
        title: 'Error',
        description: 'Employee ID is missing.',
        status: 'error',
      });
      return;
    }

    if (!isMarried) {
      toast.showToast({
        title: 'Error',
        description: 'Only married employees can add family details.',
        status: 'error',
      });
      return;
    }

    if (mode === 'Add') {
      dispatch(addFamilyDetails(empId, formState));
    } else {
      dispatch(editFamilyDetails(empId, formState));
    }
  };

  if (isMarried === false) {
    return <NoDataFound msg = {"Family details are only available for married employees."} />
  }
  if (loading) {
    return  <Loader dims='mt-[18vh] mb-[18vh] mx-auto' />
  }
  return (
    <form onSubmit={handleSubmit} className="mx-4">
      <Flex justify="space-between" align="center" mb={1}>
        <FormControl isRequired className="flex flex-col mx-1 mb-3">
          <InputField
            type="text"
            label="Name of Spouse"
            placeholder="Enter spouse name"
            id="spouse_name"
            value={formState.spouse_name}
            onChange={(e) => handleInputChange('spouse_name', e.target.value)}
            required
          />
        </FormControl>

        <FormControl isRequired className="flex flex-col mx-1 mb-3">
          <DatePicker
            placeholder="Spouse's DOB"
            id="spouse_dob"
            label="Date of Birth"
            startDate={formState.spouse_dob}
            setStartDate={(date) => handleInputChange('spouse_dob', date)}
            required
          />
        </FormControl>
      </Flex>

      <FormControl isRequired flex="1" mb={4}>
        <InputField
          type="number"
          label="Number of Children"
          placeholder="Enter number of children"
          id="number_of_children"
          value={formState.number_of_children}
          onChange={(e) => handleNumberOfChildrenChange(e.target.value)}
          required
        />
      </FormControl>

      {formState.children.map((child, index) => (
        <Flex key={index} wrap="wrap" mb={4} alignItems="center">
          <FormControl flex="1" mr={4} mb={4}>
            <InputField
              label={`Child ${index + 1} Name`}
              type="text"
              placeholder={`Enter child ${index + 1} name`}
              id={`child_name_${index}`}
              value={child.name}
              onChange={(e) => handleChildrenChange(index, 'name', e.target.value)}
              required
            />
          </FormControl>

          <FormControl flex="1" mb={4}>
            <DatePicker
              placeholder={`Enter child ${index + 1} date of birth`}
              id={`child_dob_${index}`}
              label={`Child ${index + 1} DOB`}
              startDate={child.dob}
              setStartDate={(date) => handleChildrenChange(index, 'dob', date)}
              required
            />
          </FormControl>
        </Flex>
      ))}

      <Flex justify="end" mt={4}>
        <button
          type="submit"
          className="float-right mb-3 mr-3 mt-2 rounded-md bg-brand-500 px-5 py-2 text-base font-medium text-white shadow-sm hover:bg-brand-600"
        >
          Save
        </button>
      </Flex>
    </form>
  );
};

export default FamilyDetailsForm;
