import { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";

const SearchInput = (props) => {
  const { onChange, placeholder } = props
  const [searchInputDebounce, setSearchInputDebounce] = useState('')

  // ** Debounce search input
  useEffect(() => {
    const timer = setTimeout(() => {
      onChange(searchInputDebounce)
    }, 1000)

    return () => clearTimeout(timer)
  }, [searchInputDebounce, onChange])

  const handleSearchInputChange = (e) => {
    setSearchInputDebounce(e.target.value)
  }
  return (
    <div className="flex mt-8 mb-3 ml-3 h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-700 dark:text-white w-[270px]">
      <p className="pl-3 pr-2 text-xl">
        <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
      </p>
      <input
        type="text"
        placeholder={placeholder ?? "Search..."}
        className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-700 p-3 dark:text-white dark:placeholder:!text-white min-w-fit"
        // value={props.value}
        onChange={handleSearchInputChange}
      />
    </div>
  );
}

export default SearchInput;