import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  session: null,
  employee: null
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setSession: (state, action) => {
      state.session = action.payload;
    },
    setLoggedInEmployee: (state, action) => {
      state.employee = action.payload;
    },
  },
});

export const { setSession, setLoggedInEmployee } = authSlice.actions;

export default authSlice.reducer;
