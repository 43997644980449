import React from "react";

const NoDataFound = ({msg}) => {
  return (
    <div className='flex space-x-2 justify-center items-center min-h-[40vh]'>
      <span className='msg'>{msg}</span>
    </div>
  );
};

export default NoDataFound;