import React, { useEffect, useMemo } from "react";
import { Card } from "@chakra-ui/react";
import SearchInput from "components/search/Search";
import { useDispatch, useSelector } from 'react-redux';
import { setSearch, setPage, setSort, setAlphaFilter, openForm } from "./reducers/leavesQuotaReducers";
import { fetchall, getAllLeavesQuota } from "./api/leavesQuota";
import { PAGINATION_DEFAULT_PAGE_SIZE } from "variables/common";
import { exportToExcel } from "utils/exportUtils";
import { TbFileExport } from "react-icons/tb";
import { MdAdd } from "react-icons/md";
import CustomTable from "views/admin/tables/components/CustomTable";
import AlphabetFilter from "components/search/AlphabetFilter";
import AdditionalLeaveForm from "./components/AdditionalLeaveForm";

const LeavesQuota = () => {
  const {
    leavesQuota, search, page, pagination, isLoading, sortBy, alphaFilter, isFormOpen
  } = useSelector((state) => state.leavesQuota);
  const { employee } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (employee && employee?.employee_id) dispatch(getAllLeavesQuota(employee, search, page, sortBy, alphaFilter));
  }, [dispatch, employee, search, page, sortBy, alphaFilter]);

  const handlePageChange = (newPage) => {
    dispatch(setPage(newPage + 1));
  };

  const handleSortChange = (newSortBy) => {
    dispatch(setSort(newSortBy));
  };

  const handleExportToExcel = () => {
    fetchall().then(({ data }) => {
      const dataToExport = data.map(row => ({
        Employee: row.employee_name,
        Annual: `${row.annual_availed - Math.min(row.carry_forward_availed, row.carry_forward_total)} / ${row.annual_total}`,
        Medical: `${row.medical_availed} / ${row.medical_total}`,
        'Carry-Forward': `${Math.min(row.carry_forward_availed, row.carry_forward_total)} / ${row.carry_forward_total}`,
        ...(employee?.employee_role_name === "hr-manager" ? { Special: row.special_availed } : {}),
        Maternity: row.employee_gender === "female" ? `${row.parental_availed} / ${row.parental_total}` : "-",
        Paternity: row.employee_gender === "male" ? `${row.parental_availed} / ${row.parental_total}` : "-"
      }));
      exportToExcel(dataToExport, 'leaves_summary');
    });
  };

  const pageSize = PAGINATION_DEFAULT_PAGE_SIZE;
  const data = useMemo(() => leavesQuota, [leavesQuota]);
  const columns = useMemo(() => [
    {
      Header: 'Employee',
      accessor: 'employee_name',
      Cell: ({ value }) => <div className="max-w-[300px] truncate" title={value}>{value}</div>,
    },
    {
      Header: 'Annual',
      disableSortBy: true,
      Cell: ({ row }) => (
        `${row.original.annual_availed - Math.min(row.original.carry_forward_availed, row.original.carry_forward_total)} / ${row.original.annual_total}`
      ),
    },
    {
      Header: 'Medical',
      disableSortBy: true,
      Cell: ({ row }) => (
        `${row.original.medical_availed} / ${row.original.medical_total}`
      ),
    },
    {
      Header: 'Carry Forward',
      disableSortBy: true,
      Cell: ({ row }) => (
        `${Math.min(row.original.carry_forward_availed, row.original.carry_forward_total)} / ${row.original.carry_forward_total}`
      ),
    },
    {
      Header: 'Maternity',
      disableSortBy: true,
      Cell: ({ row }) => (
        row.original.employee_gender === "female" ? `${row.original.parental_availed} / ${row.original.parental_total}` : "-"
      ),
    },
    {
      Header: 'Paternity',
      Cell: ({ row }) => (
        row.original.employee_gender === "male" ? `${row.original.parental_availed} / ${row.original.parental_total}` : "-"
      ),
    },
    ...(employee && (employee?.employee_role_name === "hr-manager" || employee?.employee_role_name === "top-management" )? [
      {
        Header: 'Special',
        Cell: ({ row }) => (
          `${row.original.special_availed > 0 ? row.original.special_availed : "-"}`
        ),
      },
    ] : [])
  ], [employee]);

  return (
    <>
      {isFormOpen && (
        <div className="mb-6 ml-5">
          <AdditionalLeaveForm />
        </div>
      )}

      <div className="my-6 ml-4">
        <Card className="bg-white dark:!bg-navy-800 px-4 pb-2 dark:shadow-none dark:text-lightPrimary ">
          <div className="flex mb-3 mx-[1rem] h-full items-center justify-between">
            <SearchInput
              value={search}
              onChange={(val) => dispatch(setSearch(val))}
              placeholder="Search by employee name.."
            />
            <div className="flex items-center">
              <button
                onClick={handleExportToExcel}
                title="Export to Excel"
                className="ml-2 text-xl text-brand-500 hover:text-brand-600 active:text-brand-700 dark:text-brand-700"
              >
                <TbFileExport size={30} />
              </button>
              {!isFormOpen && (employee?.employee_role_name === "hr-manager" || employee?.employee_role_name === "top-management") && (
                <button
                  onClick={() => dispatch(openForm())}
                  title="Additional Leaves"
                  className="ml-2 text-xl text-brand-500 hover:text-brand-600 active:text-brand-700 dark:text-brand-700"
                >
                  <MdAdd size={35} />
                </button>
              )}
            </div>
          </div>

          <div className="mx-[1rem] my-2 w-full ">
            <AlphabetFilter value={alphaFilter} handleAlphabetClick={(val) => dispatch(setAlphaFilter(val))} />
          </div>

          <CustomTable
            columns={columns}
            data={data}
            isLoading={isLoading}
            pageIndex={page - 1}
            pageSize={pageSize}
            pageCount={Math.ceil(pagination.total / pageSize)}
            gotoPage={handlePageChange}
            canPreviousPage={page > 1}
            canNextPage={page * pageSize < pagination.total}
            totalItems={pagination.total}
            sortBy={sortBy}
            setSortBy={handleSortChange}
            maxHeight="52vh"
          />
        </Card>
      </div>
    </>
  );
};

export default LeavesQuota;
