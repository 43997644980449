import { setMedicalHistory, setFamilyMembers, setIsMedicalHistoryLoading } from '../reducers/manageMedicalInfoReducers';
import customToast from 'utils/toastUtils';
import { supabase } from 'utils/supabase';

const toast = customToast();

export const addMedicalHistory = (medicalHistoryData) => async () => {
  let success = false;
  try {
    let dataToAdd = medicalHistoryData;
    dataToAdd.forEach(data => delete data.medical_hist_id);
    console.log({ dataToAdd })
    const { error } = await supabase.from('medical_history').insert(dataToAdd).select();

    if (error) {
      console.log(`Error adding medical history: ${error.message}`)
      toast.showToast({
        title: 'Error',
        description: 'Unable to add medical history.',
        status: 'error',
      });
    } else { success = true }
  } catch (error) {
    toast.showToast({
      title: 'Error',
      description: 'Unable to save medical history.',
      status: 'error',
    });
  }
  return success
};

export const editMedicalHistory = (medicalHistoryData) => async () => {
  let isUpdated = false
  try {
    const { error } = await supabase
      .from('medical_history')
      .upsert(medicalHistoryData)
      .select()

    if (error) {
      console.log(`Error editing medical history: ${error.message}`)
      toast.showToast({
        title: 'Error',
        description: 'Unable to update medical history.',
        status: 'error',
      });
    } else { isUpdated = true; }
  } catch (error) {
    toast.showToast({
      title: 'Error',
      description: 'Unable to update medical history.',
      status: 'error',
    });
  }
  return isUpdated
};


export const fetchFamilyDetailsByEmployeeId = (employeeId) => async (dispatch) => {
  try {
    dispatch(setIsMedicalHistoryLoading(true));
    const { data, error } = await supabase
      .from('family_members')
      .select('family_member_id, name, date_of_birth, family_member_type')
      .eq('employee_id', employeeId);

    if (error) {
      toast.showToast({
        title: 'Error fetching family details',
        description: error.message,
        status: 'error',
      });
      dispatch(setIsMedicalHistoryLoading(false))
      return;
    }
    dispatch(setFamilyMembers(data));
    dispatch(setIsMedicalHistoryLoading(false))
    return data ?? []
  } catch (error) {
    toast.showToast({
      title: 'Error',
      description: 'Unable to fetch family details.',
      status: 'error',
    });
    dispatch(setIsMedicalHistoryLoading(false))
  }
};

export const fetchMedicalHistoryByEmployeeId = (employeeId, familyMembers) => async (dispatch) => {
  try {
    const familyIds = familyMembers?.map((fam => fam?.family_member_id)) ?? []
    dispatch(setIsMedicalHistoryLoading(true));
    const { data, error } = await supabase
      .from('medical_history')
      .select('*')
      .in('person_id', [employeeId, ...familyIds]);

    if (error) {
      console.log(`Error fetching medical history: ${error.message}`);
      toast.showToast({
        title: 'Error',
        description: 'Error fetching medical history',
        status: 'error',
      });
    }
    console.log('med', { data })
    const medicalHistory = {
      self: data.find((item) => item.person_id === employeeId && item.medical_hist_type === 'self') || null,
      family: familyMembers.map(member => ({
        ...member, ...data?.find((item) => item.person_id === member?.family_member_id && item.medical_hist_type === 'family')
      }))
    };
    dispatch(setMedicalHistory(medicalHistory));
    dispatch(setIsMedicalHistoryLoading(false));
  } catch (error) {
    console.log("errr", error)
    toast.showToast({
      title: 'Error',
      description: 'Unable to fetch medical history.',
      status: 'error',
    });
    dispatch(setIsMedicalHistoryLoading(false));
  }
};

