import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  leaveRequests: [],
  exceldata: [],
  leaveTypes: [],
  page: 1,
  isFormOpen: false,
  selectedLeave: null,
  isDeleteConfirmationOpen: false,
  search: '',
  holidays: [],
  isLoading: false,
  employees: [],
  pagination: {
    total: 0,
  },
  sortBy: [{ id: 'end_date', desc: true }]
};

const manageLeavesReducers = createSlice({
  name: 'manageLeaves',
  initialState,
  reducers: {
    setLeaveRequests: (state, action) => {
      state.leaveRequests = action.payload;
    },
    setLeaveTypes: (state, action) => {
      state.leaveTypes = action.payload;
    },
    setPage: (state, action) => {
      state.page = Number(action.payload)
    },
    setPaginationData: (state, action) => {
      state.pagination = action.payload
    },
    openAddForm: (state) => {
      state.isFormOpen = true;
      state.mode = "Add"
    },
    openEditForm: (state, action) => {
      state.isFormOpen = true;
      state.mode = "Edit"
      state.selectedLeave = action.payload;
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    closeForm: (state) => {
      state.isFormOpen = false;
      state.selectedLeave = null;
    },
    setSelectedLeave: (state, action) => {
      state.selectedLeave = action.payload;
    },
    openDeleteConfirmation: (state, action) => {
      state.isFormOpen = false;
      state.isDeleteConfirmationOpen = true;
      state.selectedLeave = action.payload;
    },
    closeDeleteConfirmation: (state) => {
      state.isDeleteConfirmationOpen = false;
      state.selectedLeave = null;
    },
    setSearch: (state, action) => {
      if (action.payload && state.search !== action.payload) state.page = 1;  // reset page on new search
      state.search = action.payload;
    },
    setHolidaysList: (state, action) => {
      state.holidays = action.payload
    },
    setEmployeesList: (state, action) => {
      state.employees = action.payload
    },
    setExcel: (state, action) => {
      state.exceldata = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setSort: (state, action) => {
      state.sortBy = action.payload;
    },
  },
});

export const {
  setLeaveRequests,
  setLeaveTypes,
  openEditForm,
  openAddForm,
  setEmployeesList,
  closeForm,
  setSelectedLeave,
  openDeleteConfirmation,
  closeDeleteConfirmation,
  setSearch,
  setPage,
  setPaginationData,
  setHolidaysList,
  setExcel,
  setIsLoading,
  setSort
} = manageLeavesReducers.actions;

export default manageLeavesReducers.reducer;
