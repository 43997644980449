/* eslint-disable */
import Links from "./components/Links";
import routes from "routes";
import { Link } from "react-router-dom";
import LogoLight from 'assets/img/icons/logo-png.png'
import LogoDark from 'assets/img/icons/logo-Reverse-png.png'
import { Image } from "@chakra-ui/react";
import { useSelector } from "react-redux";

const Sidebar = ({ open }) => {
  const { darkMode } = useSelector((state) => state.layout);
  return (
    <div
      className={`fixed top-0 left-0 z-50 flex flex-col min-h-full bg-white pb-5 shadow-2xl shadow-white/5 transition-transform duration-175 dark:bg-navy-800 dark:text-white ${open ? "translate-x-0" : "-translate-x-96"} max-h-screen w-64`}
    >
      <div className={`mx-4 my-2 flex items-center justify-center`}>
        <div className=" ml-1 text-2xl font-bold uppercase text-navy-700 dark:text-white">
          <Link to="/">
            <Image src={darkMode ? LogoDark : LogoLight} height='120px' width='auto' />
          </Link>
        </div>
      </div>
      <div className=" mb-7 h-px bg-gray-300 dark:bg-white/30" />

      {/* Scrollable routes container */}
      <div className="flex-1 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100">
        <ul className="pt-1">
          <Links routes={routes} />
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
