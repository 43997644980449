import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sideBarOpen: true,
  currentRoute: "Main Dashboard",
  darkMode: false,
  loading: false
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setSideBar: (state, action) => {
      state.sideBarOpen = action.payload;
    },
    setCurrentRoute: (state, action) => {
      state.currentRoute = action.payload;
    },
    setDarkMode: (state, action) => {
      state.darkMode = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    }
  },
});

export const { setSideBar, setCurrentRoute, setDarkMode, setLoading } = layoutSlice.actions;

export default layoutSlice.reducer;
