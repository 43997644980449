import React, { useEffect, useState } from 'react';
import { FormControl, Flex } from '@chakra-ui/react';
import { MdDelete, MdAdd } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import {
  addAssetDetails,
  editAssetDetails,
  fetchAssetDetailsByEmployeeId,
} from '../api/manageAssets';
import InputField from 'components/fields/InputField';
import SelectField from 'components/fields/SelectField';
import customToast from 'utils/toastUtils';
import Loader from 'components/loader/miniLoader';

const assetOptions = [
  { id: 'Laptop', value: 'Laptop' },
  { id: 'Laptop Charger', value: 'Laptop Charger' },
  { id: 'Laptop Bag', value: 'Laptop Bag' },
  { id: 'Monitor', value: 'Monitor' },
  { id: 'Mouse', value: 'Mouse' },
  { id: 'Headset', value: 'Headset' },
  { id: 'Other', value: 'Other' },
];

const AssetDetailsForm = ({ mode, employeeId }) => {
  const dispatch = useDispatch();
  const toast = customToast();

  const { newEmployeeData } = useSelector(
    (state) => state.manageEmployees
  );
  const { assetDetails, isAssetDetailsLoading } = useSelector(
    (state) => state.manageAssets
  );

  const [formState, setFormState] = useState([
    { asset_type: '', serial_number: '', asset_id: '' },
  ]);

  useEffect(() => {
    if (mode === 'Edit' && employeeId) {
      dispatch(fetchAssetDetailsByEmployeeId(employeeId));
    }
  }, [dispatch, mode, employeeId]);

  useEffect(() => {
    if (assetDetails && mode === 'Edit') {
      // Check if assetDetails is an array. If not, convert it into an array.
      if (Array.isArray(assetDetails)) {
        setFormState(assetDetails.map((asset) => ({
          asset_id: asset.asset_id,  // Include asset_id
          asset_type: asset.asset_type || '',
          serial_number: asset.serial_number || '',
        })));
      } else {
        // If it's a single object, convert it to an array with one element.
        setFormState([{
          asset_id: assetDetails.asset_id || '',
          asset_type: assetDetails.asset_type || '',
          serial_number: assetDetails.serial_number || '',
        }]);
      }
    }
  }, [assetDetails, mode]);

  const handleInputChange = (index, field, value) => {
    const updatedFormState = formState.map((asset, i) =>
      i === index ? { ...asset, [field]: value } : asset
    );
    setFormState(updatedFormState);
  };

  const handleAddAsset = () => {
    setFormState([...formState, { asset_type: '', serial_number: '', asset_id: '' }]);
  };

  const handleRemoveAsset = (index) => {
    const updatedFormState = formState.filter((_, i) => i !== index);
    setFormState(updatedFormState);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const empId = mode === 'Add' ? newEmployeeData?.employee_id : employeeId;

    if (!empId) {
      toast.showToast({
        title: 'Error',
        description: 'Employee ID is missing.',
        status: 'error',
      });
      return;
    }

    const payload = formState;

    if (mode === 'Add') {
      dispatch(addAssetDetails(empId, payload));
    } else {
      dispatch(editAssetDetails(empId, payload));
    }
  };

  if (isAssetDetailsLoading) {
    return <Loader dims='mt-[18vh] mb-[18vh] mx-auto' />
  }

  return (
    <form onSubmit={handleSubmit} className="mx-4">
      {formState.map((asset, index) => (
        <Flex wrap="wrap" spacing={4} key={index} mb={4}>
          <FormControl isRequired flex="1" mr={4}>
            <SelectField
              id={`asset_type_${index}`}
              placeholder="Select asset(s)"
              label={`Asset(s) Provided ${index + 1}`}
              value={asset.asset_type}
              onChange={(e) => handleInputChange(index, 'asset_type', e.target.value)}
              options={assetOptions}
              required
            />
          </FormControl>

          <FormControl isRequired flex="1" mr={4}>
            <InputField
              type="text"
              id={`serial_number_${index}`}
              label="Serial Number"
              placeholder="Enter serial number"
              value={asset.serial_number}
              onChange={(e) => handleInputChange(index, 'serial_number', e.target.value)}
              required
            />
          </FormControl>
          <button
            onClick={() => handleRemoveAsset(index)}
            className="mt-8 text-xl text-red-500 hover:text-red-600 active:text-red-700 dark:text-white dark:disabled:text-gray-500 disabled:text-gray-500 disabled:cursor-not-allowed"
          >
            <MdDelete size={25} />
          </button>
        </Flex>
      ))}

      <button
        type="button"
        className="float-left mb-3 mr-3 mt-2 rounded-md border-2 border-brand-500 px-4 py-1 text-base font-medium text-brand-500"
        onClick={handleAddAsset}
      >
        <MdAdd className="inline mr-2" size={20} />
        Add Asset
      </button>

      <Flex justify="end" mt={4}>
        <button
          type="submit"
          className="float-right mb-3 mr-3 mt-2 rounded-md bg-brand-500 px-5 py-2 text-base font-medium text-white shadow-sm hover:bg-brand-600"
        >
          Save
        </button>
      </Flex>
    </form>
  );
};

export default AssetDetailsForm;
