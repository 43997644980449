import React, { useEffect, useState } from 'react';
import { FormControl, Flex } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addMedicalHistory,
  editMedicalHistory,
  fetchMedicalHistoryByEmployeeId,
  fetchFamilyDetailsByEmployeeId,
} from '../api/manageMedicalInfo';
import InputField from 'components/fields/InputField';
import customToast from 'utils/toastUtils';
import Loader from 'components/loader/miniLoader';
import NoDataFound from './NoDataFound';

const MedicalInfoForm = ({ mode, employeeId }) => {
  const dispatch = useDispatch();
  const toast = customToast();
  const { familyMembers, medicalHistory, isMedicalHistoryLoading } = useSelector((state) => state.manageMedical);
  const { newEmployeeData } = useSelector((state) => state.manageEmployees);
  const newemployeeId = newEmployeeData?.employee_id;

  const [formState, setFormState] = useState({
    self_medical_hist: { medical_hist_id: null, medical_hist: '' },
    family_medical_hist: [],
  });

  const fetchData = async () => {
    const employeeIdToFetch = mode === 'Edit' ? employeeId : newemployeeId;
    try {
      if (employeeIdToFetch) {
        dispatch(fetchFamilyDetailsByEmployeeId(employeeIdToFetch)).then((family) => {
          dispatch(fetchMedicalHistoryByEmployeeId(employeeIdToFetch, family));
        });
      }
    } catch (error) {
      toast.showToast({
        title: 'Error fetching data',
        description: error?.message ?? 'Something went wrong',
        status: 'error',
      });
    }
  };

  useEffect(() => {
    if (mode === 'Add' && !newemployeeId)
      toast.showToast({
        title: 'Employee not added',
        description: 'You need to fill basic details first',
        status: 'warning',
      });

    fetchData(); // Fetch data on mount
    // eslint-disable-next-line
  }, [mode, newemployeeId, employeeId, dispatch]);

  useEffect(() => {
    if (familyMembers && mode === 'Edit' && medicalHistory) {
      setFormState({
        self_medical_hist: {
          medical_hist_id: medicalHistory?.self?.medical_hist_id || null,
          medical_hist: medicalHistory?.self?.medical_hist || ''
        },
        family_medical_hist:
          familyMembers?.map((mem) => ({
            medical_hist_id: medicalHistory?.family?.find((fam) => fam.family_member_id === mem.family_member_id)
              ?.medical_hist_id || null,
            family_member_id: mem.family_member_id,
            medical_hist: medicalHistory?.family?.find((fam) => fam.family_member_id === mem.family_member_id)
              ?.medical_hist || '',
          })) || [],
      });
    }
  }, [familyMembers, mode, medicalHistory]);

  const handleSelfHistChange = (value) => {
    setFormState((prevState) => ({
      ...prevState,
      self_medical_hist: {
        ...prevState.self_medical_hist,
        medical_hist: value
      },
    }));
  };

  const handleFamilyMedicalHistChange = (index, value) => {
    const updatedFamily = [...formState.family_medical_hist];
    updatedFamily[index] = { ...updatedFamily[index], medical_hist: value };
    setFormState((prevState) => ({
      ...prevState,
      family_medical_hist: updatedFamily,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const medicalData = [];
    const personId = mode === 'Add' ? newemployeeId : employeeId;
    medicalData.push({
      person_id: personId,
      medical_hist: formState.self_medical_hist?.medical_hist,
      medical_hist_id: formState.self_medical_hist?.medical_hist_id ?? null,
      medical_hist_type: 'self',
    });

    formState.family_medical_hist.forEach((familyHist, idx) => {
      if (familyHist.medical_hist) {
        medicalData.push({
          medical_hist_id: familyHist.medical_hist_id,
          person_id: familyHist.family_member_id,
          medical_hist: familyHist.medical_hist,
          medical_hist_type: 'family',
        });
      }
    });

    // add new records
    const addResp = dispatch(addMedicalHistory(medicalData.filter(dt => dt.medical_hist_id === null)))
    // update existing records
    const editResp = dispatch(editMedicalHistory(medicalData.filter(dt => dt.medical_hist_id && dt.medical_hist_id !== null)));
    if(addResp && editResp){
      toast.showToast({
        title: 'Success',
        description: 'Medical History Saved Successfully',
        status: 'success',
      });
      // reload the data 
      fetchData();
    }
  };

  if (isMedicalHistoryLoading) {
    return  <Loader dims='mt-[18vh] mb-[18vh] mx-auto' />
  }

  if (mode === 'Add' && !newemployeeId) {
    return <NoDataFound msg = {"No employee data available for medical history."} />
  }

  return (
    <form onSubmit={handleSubmit} className="mx-4">
      <Flex wrap="wrap" mb={4}>
        <FormControl isRequired flex="1" mr={4} mb={4}>
          <InputField
            label="Pre-Existing Medical Condition of Self"
            type="text"
            placeholder="Enter medical condition for self"
            value={formState.self_medical_hist?.medical_hist}
            onChange={(e) => handleSelfHistChange(e.target.value)}
            required
          />
        </FormControl>
      </Flex>

      {familyMembers?.map((member, index) => (
        <Flex wrap="wrap" mb={4} key={member.family_member_id}>
          <FormControl flex="1" mr={4} mb={4}>
            <InputField
              label={`Pre-Existing Medical Condition of ${member.family_member_type} (${member.name})`}
              type="text"
              placeholder={`Enter medical condition for ${member.family_member_type}`}
              value={formState.family_medical_hist[index]?.medical_hist || ''}
              onChange={(e) => handleFamilyMedicalHistChange(index, e.target.value)}
            />
          </FormControl>
        </Flex>
      ))}

      <Flex justify="end" mt={4}>
        <button
          type="submit"
          className="float-right mb-3 mr-3 mt-2 rounded-md bg-brand-500 px-5 py-2 text-base font-medium text-white shadow-sm hover:bg-brand-600"
        >
          Save
        </button>
      </Flex>
    </form>
  );
};

export default MedicalInfoForm;