import React from "react";

const Loader = (props) => {
  return (
    <div className={`flex items-center ${props.dims} w-full ml-auto justify-center`}>
    <div className='flex space-x-2 justify-center items-center bg-opacity-50'>
      <span className='sr-only'>Loading...</span>
      <div className='h-4 w-4 bg-brand-500 rounded-full animate-bounce [animation-delay:-0.3s]'></div>
      <div className='h-4 w-4 bg-brand-500 rounded-full animate-bounce [animation-delay:-0.15s]'></div>
      <div className='h-4 w-4 bg-brand-500 rounded-full animate-bounce'></div>
    </div>
    </div>
  );
};

export default Loader;