import customToast from 'utils/toastUtils';
import { supabase } from 'utils/supabase';
import { setHolidays, setPaginationData, closeDeleteConfirmation, setIsLoading } from '../reducers/manageAddHolidayReducers';
import { PAGINATION_DEFAULT_PAGE_SIZE } from 'variables/common';

const toast = customToast();
const pageSize = PAGINATION_DEFAULT_PAGE_SIZE

export const getAllHolidays = (search, page, sortBy) => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    let response = null;
    if (search && search.length > 0) {
      response = await supabase
        .from('public_holidays')
        .select('*')
        .ilike('holiday_name', `%${search}%`)
        .order(sortBy[0].id, { ascending: !sortBy[0].desc })
        .range((page - 1) * pageSize, (pageSize * page) - 1);
    } else {
      response = await supabase
        .from('public_holidays')
        .select('*')
        .order(sortBy[0].id, { ascending: !sortBy[0].desc })
        .range((page - 1) * pageSize, (pageSize * page) - 1);
    }

    const { count, err } = search && search?.length > 0 ? await supabase
      .from('public_holidays')
      .select('*', { count: 'exact', head: true })
      .ilike('holiday_name', `%${search}%`) :
      await supabase
        .from('public_holidays')
        .select('*', { count: 'exact', head: true })
    if (err) toast.showToast({
      title: 'Count error.',
      description: err?.message && typeof err?.message === 'string' ? err?.message : 'Could not get total count of records.',
      status: 'error',
    })

    if (response.error) {
      console.error('Error fetching holidays:', response.error.message);
      toast.showToast({
        title: 'An error occurred.',
        description: response.error.message || 'Holiday data could not be fetched.',
        status: 'error',
      });
    } else {
      const { data } = response;
      if (data && data.length > 0) {
        dispatch(setHolidays(data));
        dispatch(setPaginationData({
          total: count ?? data.length,
        }));
      } else {
        // console.warn('No holiday data found.');
        dispatch(setHolidays([])); // Clear holidays if none found
        dispatch(setPaginationData({ total: 0 }));
      }
    }
    dispatch(setIsLoading(false))
  } catch (error) {
    // console.error('Exception in getAllHolidays:', error.message);
    toast.showToast({
      title: 'An error occurred.',
      description: 'Holiday data could not be fetched.',
      status: 'error',
    });
  }
};

export const addNewHoliday = (holidayData, search, page, sortBy) => async (dispatch) => {
  try {
    holidayData.start_date = holidayData.start_date.toLocaleDateString("en-US");
    holidayData.end_date = holidayData.end_date ? holidayData.end_date.toLocaleDateString("en-US") : holidayData.start_date;
    const { data, error } = await supabase
      .from('public_holidays')
      .insert([holidayData])
      .select();

    if (error) {
      toast.showToast({
        title: 'An error occurred.',
        description: error.message || 'Unable to add holiday.',
        status: 'error',
      });
    } else if (data && data.length > 0) {
      dispatch(getAllHolidays(search, page, sortBy));
      toast.showToast({
        title: 'Added.',
        description: 'Holiday added successfully',
        status: 'success',
      });
    }
  } catch (error) {
    console.error('Add holiday error:', error.message);
    toast.showToast({
      title: 'An error occurred.',
      description: 'Holiday data could not be added.',
      status: 'error',
    });
  }
};

export const editHoliday = (holidayData, search, page, sortBy) => async (dispatch) => {
  try {
    holidayData.start_date = holidayData.start_date.toLocaleDateString("en-US");
    holidayData.end_date = holidayData.end_date ? holidayData.end_date.toLocaleDateString("en-US") : holidayData.start_date;
    const { data, error } = await supabase
      .from('public_holidays')
      .update(holidayData)
      .eq('public_holiday_id', holidayData.public_holiday_id)
      .select();

    if (error) {
      toast.showToast({
        title: 'An error occurred.',
        description: error.message || 'Unable to update holiday.',
        status: 'error',
      });
    } else if (data && data.length > 0) {
      dispatch(getAllHolidays(search, page, sortBy));
      toast.showToast({
        title: 'Updated.',
        description: 'Holiday updated successfully',
        status: 'success',
      });
    }
  } catch (error) {
    // console.error('Edit holiday error:', error.message);
    toast.showToast({
      title: 'An error occurred.',
      description: 'Holiday data could not be updated.',
      status: 'error',
    });
  }
};

export const deleteHoliday = (holidayId, search, page, sortBy) => async (dispatch) => {
  try {
    const { error } = await supabase
      .from('public_holidays')
      .delete()
      .eq('public_holiday_id', holidayId);

    if (error) {
      toast.showToast({
        title: 'An error occurred.',
        description: error.message || 'Unable to delete holiday.',
        status: 'error',
      });
    } else {
      // Dispatch the action to fetch all holidays again after successful deletion
      dispatch(getAllHolidays(search, page, sortBy));

      // Dispatch the action to close the delete confirmation dialog
      dispatch(closeDeleteConfirmation());

      toast.showToast({
        title: 'Deleted.',
        description: 'Holiday deleted successfully',
        status: 'success',
      });
    }
  } catch (error) {
    // console.error('Delete holiday error:', error.message);
    toast.showToast({
      title: 'An error occurred.',
      description: 'Holiday data could not be deleted.',
      status: 'error',
    });
  }
};

export const fetchHolidaysList = async () => {
  let holidays = []
  try {
    const { data, error } = await supabase.from('public_holidays').select('start_date, end_date');
    if (error) {
      throw error;
    }
    // Extract and format dates from the fetched data
    const holidayDates = data.reduce((acc, holiday) => {
      const startDate = new Date(holiday.start_date);
      const endDate = new Date(holiday.end_date);
      // Create an array of dates between start_date and end_date
      const dateRange = [];
      let currentDate = startDate;
      while (currentDate <= endDate) {
        dateRange.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }
      return [...acc, ...dateRange];
    }, []);
    holidays = holidayDates.map(date => date.toLocaleDateString("en-US"));
  } catch (error) {
    console.error('Error fetching holidays:', error.message);
    toast.showToast({
      title: 'An error occurred.',
      description: 'Holidays data could not be added.',
      status: 'error',
    });
  }
  return holidays
};