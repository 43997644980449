import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";

const Modal = (props) => {
  const { isOpen, title, onClose, children, size, className } = props;
  const maxWidthStyle = size ? {} : { width: "max-content", maxWidth: "80%", minWidth: "30%" };

  return (
    <>
      <ChakraModal size={size ?? "md"} closeOnOverlayClick={true} onClose={onClose} isOpen={isOpen} isCentered scrollBehavior='inside'>
        <ModalOverlay />
        <ModalContent style={maxWidthStyle} className={className ?? "min-h-[30vh]"}>
          <ModalHeader className="capitalize">{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{children}</ModalBody>
        </ModalContent>
      </ChakraModal>
    </>
  );
};

export default Modal;
