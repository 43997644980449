import { setContactDetailKeys, setIsContactDetailsLoading, setContactDetails, } from '../reducers/manageContactReducers'
import customToast from 'utils/toastUtils';
import { supabase } from 'utils/supabase';

const toast = customToast();

export const fetchContactDetailKeys = () => async (dispatch) => {
  try {
    dispatch(setIsContactDetailsLoading(true));
    const { data, error } = await supabase
      .from('contact_details_keys')
      .select('*')
      .order('display_order', { ascending: true });

    if (error) {
      toast.showToast({
        title: 'Error fetching contact detail keys',
        description: error.message,
        status: 'error',
      });
    } else {
      dispatch(setContactDetailKeys(data));
    }
    dispatch(setIsContactDetailsLoading(false));
  } catch (error) {
    toast.showToast({
      title: 'Error',
      description: 'Unable to fetch contact detail keys.',
      status: 'error',
    });
    dispatch(setIsContactDetailsLoading(false));
  }
};

export const addContactDetails = (employeeId, contactDetails) => async (dispatch, getState) => {
  try {
    const { contactDetailKeys } = getState().manageContacts;
    

    const contactDetailsArray = contactDetailKeys.map((keyItem) => {
      return {
        employee_id: employeeId,
        contact_detail_key_id: keyItem.contact_detail_key_id,
        value: contactDetails[keyItem.contact_detail_key_id] || '',  
      };
    });

    const { error } = await supabase
      .from('contact_details')
      .insert(contactDetailsArray)
      .select();

    if (error) {
        console.error("error",error)
      toast.showToast({
        title: 'Error adding contact details',
        description: error.message,
        status: 'error',
      });
    } else {
      toast.showToast({
        title: 'Success',
        description: 'Contact details added successfully.',
        status: 'success',
      });
    }
  } catch (error) {
    console.error("error",error)
    toast.showToast({
      title: 'Error',
      description: 'Unable to add contact details.',
      status: 'error',
    });
  }
};

export const fetchEmployeeById = (employeeId) => async () => {
  try {
    const { data, error } = await supabase
      .from('employee')
      .select('maritial_status')
      .eq('employee_id', employeeId)
      .single(); 

    if (error) {
      throw new Error(`Error fetching employee details: ${error.message}`);
    }

    return data;
  } catch (error) {
    toast.showToast({
      title: 'Error',
      description: 'Unable to fetch employee details.',
      status: 'error',
    });
    return null;
  }
};

export const editContactDetails = (employeeId, contactDetails) => async (dispatch, getState) => {
  try {
    const { contactDetailKeys } = getState().manageContacts;
    
    for (let keyItem of contactDetailKeys) {
      const contactDetailKeyId = keyItem.contact_detail_key_id;
      const value = contactDetails[contactDetailKeyId] || '';
      
      if (value !== '') {
        const { error } = await supabase
          .from('contact_details')
          .update({ value })
          .eq('employee_id', employeeId)
          .eq('contact_detail_key_id', contactDetailKeyId);

        if (error) {
          throw new Error(`Error updating contact detail for key: ${keyItem.key_name}. ${error.message}`);
        }
      }
    }
    dispatch({
      type: 'CONTACT_DETAILS_UPDATED',
      payload: { employeeId, contactDetails },
    });

    toast.showToast({
      title: 'Success',
      description: 'Contact details updated successfully.',
      status: 'success',
    });
  } catch (error) {
    console.error('Error during contact details update:', error);
    toast.showToast({
      title: 'Error',
      description: 'Unable to update contact details.',
      status: 'error',
    });
    dispatch({
      type: 'CONTACT_DETAILS_UPDATE_FAILED',
      payload: error.message,
    });
  }
};


export const fetchContactDetailsByEmployeeId = (employeeId) => async (dispatch) => {
  try {
    dispatch(setIsContactDetailsLoading(true));

    const { data, error } = await supabase
      .from('contact_details')
      .select('contact_detail_key_id, value')
      .eq('employee_id', employeeId);

    if (error) {
      toast.showToast({
        title: 'Error fetching contact details',
        description: error.message,
        status: 'error',
      });
    } else {

      const contactDetails = {};
      data.forEach((item) => {
        contactDetails[item.contact_detail_key_id] = item.value;
      });
      dispatch(setContactDetails(contactDetails));
    }
    dispatch(setIsContactDetailsLoading(false));
  } catch (error) {
    toast.showToast({
      title: 'Error',
      description: 'Unable to fetch contact details.',
      status: 'error',
    });
    dispatch(setIsContactDetailsLoading(false));
  }
};



