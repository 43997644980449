import React, { useEffect, useMemo, useState } from "react";
import { Card } from "@chakra-ui/react";
import { MdAdd, MdBarChart, MdDelete, MdEdit, MdOpenInNew, MdReceipt } from "react-icons/md";
import EmployeeForm from "./components/LeaveRequestForm";
import { useDispatch, useSelector } from 'react-redux';
import { closeDeleteConfirmation, openAddForm, openDeleteConfirmation, openEditForm, setPage, closeForm, setSearchDate, setSort, setLeaveTypes } from "./reducers/myLeavesReducers";
import CustomAlertDialog from "components/alertDialog/AlertDialog";
import { deleteLeaveRequest, downloadAttachmentsFromStorage, getAllLeaveRequests, getAllLeaveTypes, getEmployeeLeaveQuota } from "./api/myLeaves";
import { PAGINATION_DEFAULT_PAGE_SIZE } from "variables/common";
import Modal from "components/modal/Modal";
import { applyTagStyles } from "variables/regexVars";
import { FaBriefcaseMedical, FaFemale, FaForward, FaMale } from "react-icons/fa";
import LeaveQuotaWidget from "./components/LeaveQuotaWidget";
import { hasDateRangePassed } from "utils/dateUtils";
import DateFilter from "components/search/DateFilter";
import { formatDisplayDate } from "utils/dateUtils";
import CustomTable from "views/admin/tables/components/CustomTable";
import { TiStarFullOutline } from "react-icons/ti";
import { LEAVE_ATTACHMENTS_STORAGE } from "variables/common";
import AvgLeaveNoticeWidget from "./components/AvgLeaveNoticeWidget";

const MyLeaves = () => {
  const {
    isFormOpen, leaveRequests, mode, selectedLeave, isDeleteConfirmationOpen, page, pagination,
    leaveTypes, employeeLeaveQuota, searchDate, isLoading, sortBy
  } = useSelector((state) => state.myLeaves);
  const { employee } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [details, setDetails] = useState(null);

  useEffect(() => {
    if (employee && employee?.employee_id) dispatch(getAllLeaveRequests(employee?.employee_id, searchDate, page, sortBy));
    dispatch(closeForm())
  }, [dispatch, employee, searchDate, page, sortBy]);

  useEffect(() => {
    if (employee && employee?.employee_id) {
      getAllLeaveTypes(employee).then((typesData) => {
        dispatch(setLeaveTypes(typesData?.filter(lv => lv.leave_type_name !== "special")))
      });
      dispatch(getEmployeeLeaveQuota(employee?.employee_id));
    }
  }, [dispatch, employee]);

  const handlePageChange = (newPage) => {
    dispatch(setPage(newPage + 1));
  };

  const handleSortChange = (newSortBy) => {
    dispatch(setSort(newSortBy));
  };

  const pageSize = PAGINATION_DEFAULT_PAGE_SIZE
  const data = useMemo(() => leaveRequests, [leaveRequests]);
  const columns = useMemo(() => [
    {
      Header: 'Leave Type',
      accessor: 'leave_type_label',
    },
    {
      Header: 'Start Date',
      accessor: 'start_date',
      Cell: ({ value }) => (value ? formatDisplayDate(value) : value),
    },
    {
      Header: 'End Date',
      accessor: 'end_date',
      Cell: ({ value }) => (value ? formatDisplayDate(value) : value),
    },
    {
      Header: 'Duration',
      accessor: 'leave_duration',
      Cell: ({ row }) => (row.original.is_half_day ? 0.5 : row.original.leave_duration),
    },
    {
      Header: 'Leave Notice Period',
      accessor: 'leave_notice_period',
    },
    {
      Header: 'Reason',
      accessor: 'leave_reason',
      disableSortBy: true,
      Cell: ({ row }) => (
        <button
          onClick={() => setDetails(row.original)}
          disabled={(!row.original.leave_reason || row.original.leave_reason === "") && row.original.files?.length === 0}
          className="text-xl text-blue-500 hover:text-blue-600 active:text-blue-700 dark:text-white dark:disabled:text-gray-500 disabled:text-gray-500 disabled:cursor-not-allowed"
        >
          <MdReceipt size={25} />
        </button>
      ),
    },
    {
      Header: 'Edit',
      disableSortBy: true,
      Cell: ({ row }) => (
        <button
          onClick={() => dispatch(openEditForm(row.original))}
          disabled={hasDateRangePassed(row.original.start_date, row.original.end_date)}
          className="text-xl text-brand-500 hover:text-brand-600 active:text-brand-700 dark:text-white dark:disabled:text-gray-500 disabled:text-gray-500 disabled:cursor-not-allowed"
        >
          <MdEdit size={25} />
        </button>
      ),
    },
    {
      Header: 'Delete',
      disableSortBy: true,
      Cell: ({ row }) => (
        <button
          onClick={() => dispatch(openDeleteConfirmation(row.original))}
          disabled={hasDateRangePassed(row.original.start_date, row.original.end_date)}
          className="text-xl text-red-500 hover:text-red-600 active:text-red-700 dark:text-white dark:disabled:text-gray-500 disabled:text-gray-500 disabled:cursor-not-allowed"
        >
          <MdDelete size={25} />
        </button>
      ),
    },
  ], [dispatch]);

  return (
    <>
      {employeeLeaveQuota && <div className={`mt-6 mx-4 grid grid-cols-1 gap-5 md:grid-cols-2 ${employeeLeaveQuota?.special_availed > 0 ? 'lg:grid-cols-6 2xl:grid-cols-6' : 'lg:grid-cols-5 2xl:grid-cols-5'} 3xl:grid-cols-6`}>
        <LeaveQuotaWidget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"annual / casual"}
          availed={(employeeLeaveQuota.annual_availed - Math.min(employeeLeaveQuota.carry_forward_availed, employeeLeaveQuota.carry_forward_total))}
          total={employeeLeaveQuota.annual_total}
        />
        <LeaveQuotaWidget
          icon={<FaBriefcaseMedical className="h-6 w-6" />}
          title={"medical"}
          availed={employeeLeaveQuota.medical_availed}
          total={employeeLeaveQuota.medical_total}
        />
        <LeaveQuotaWidget
          icon={<FaForward className="h-6 w-6" />}
          title={"carry-forward"}
          availed={Math.min(employeeLeaveQuota.carry_forward_availed, employeeLeaveQuota.carry_forward_total)}
          total={employeeLeaveQuota.carry_forward_total}
        />
        {employeeLeaveQuota && employeeLeaveQuota?.special_availed > 0 && (
          <LeaveQuotaWidget
            icon={<TiStarFullOutline className="h-7 w-7" />}
            title={"special"}
            availed={employeeLeaveQuota.special_availed}
            total={employeeLeaveQuota.special_availed}
            hideTotal={true}
          />
        )}
        {employee?.employee_gender === "male" ?
          <LeaveQuotaWidget
            icon={<FaMale className="h-7 w-7" />}
            title={"paternity"}
            availed={employeeLeaveQuota.parental_availed}
            total={employeeLeaveQuota.parental_total} /> :
          <LeaveQuotaWidget
            icon={<FaFemale className="h-7 w-7" />}
            title={"maternity"}
            availed={employeeLeaveQuota.parental_availed}
            total={employeeLeaveQuota.parental_total}
          />
        }
        {employee && <AvgLeaveNoticeWidget employee_id={employee?.employee_id} />}
      </div>}
      {isFormOpen && <EmployeeForm
        mode={mode}
        data={selectedLeave}
      />}
      <div className="my-6 ml-4">
        <Card className="bg-white dark:!bg-navy-800 px-4 pb-2 dark:shadow-none dark:text-lightPrimary ">
          <div className="flex mb-3 mx-[1rem] h-full items-center justify-between">
            <DateFilter
              searchDate={searchDate ? new Date(searchDate) : searchDate}
              setSearchDate={(date) => dispatch(setSearchDate(date ? date.toLocaleDateString("en-US") : null))}
            />
            {!isFormOpen && <button
              onClick={() => dispatch(openAddForm())}
              className="text-xl text-brand-500 hover:text-brand-600 active:text-brand-700 dark:text-brand-600"
            >
              <MdAdd size={35} />
            </button>}
          </div>
          <CustomTable
            columns={columns}
            data={data}
            isLoading={isLoading}
            pageIndex={page - 1}
            pageSize={pageSize}
            pageCount={Math.ceil(pagination.total / pageSize)}
            gotoPage={handlePageChange}
            canPreviousPage={page > 1}
            canNextPage={page * pageSize < pagination.total}
            totalItems={pagination.total}
            sortBy={sortBy}
            setSortBy={handleSortChange}
            maxHeight="45vh"
            minHeight="40vh"
          />
        </Card>
      </div>
      <Modal title="Leave details" isOpen={details !== null} onClose={() => setDetails(null)}>
        <p style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: applyTagStyles(details?.leave_reason ?? "") }} />
        {details && details?.files?.length > 0 && (
          <>
            <h3 className="my-2 text-sm font-medium text-gray-600">Attachments</h3>
            {details?.files.map((file, index) => {
              return (
                <p className="mt-2 text-sm font-medium px-2 py-1 rounded-md bg-gray-600 flex gap-4 items-center justify-between">
                  <span className="cursor-default">{file?.startsWith(`${LEAVE_ATTACHMENTS_STORAGE}/`) ? file.split('/').slice(-1) : file}</span>
                  <MdOpenInNew className="cursor-pointer" onClick={() => downloadAttachmentsFromStorage(file)} fontSize={18} />
                </p>
              )
            })}
          </>
        )}
      </Modal>
      <CustomAlertDialog
        isOpen={isDeleteConfirmationOpen}
        onClose={() => dispatch(closeDeleteConfirmation())}
        onConfirm={() => {
          if (selectedLeave && selectedLeave?.leave_request_id) {
            dispatch(
              deleteLeaveRequest(
                selectedLeave,
                employee, searchDate, page,
                // isMedicalLeave(selectedLeave?.leave_type_id) ? selectedLeave?.files : []
                leaveTypes, sortBy
              )
            )
          }
        }}
        header="Confirm Delete"
        body="Are you sure you want to delete this leave request?"
        confirmText="Delete"
      />
    </>
  )
}

export default MyLeaves;