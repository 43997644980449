import React, { useEffect, useState } from 'react';
import { FormControl, Flex } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchContactDetailKeys,
  addContactDetails,
  editContactDetails,
  fetchContactDetailsByEmployeeId,
} from '../api/manageContacts';
import InputField from 'components/fields/InputField';
import customToast from 'utils/toastUtils';
import Loader from 'components/loader/miniLoader';

const ContactInfoForm = ({ mode, employeeId }) => {
  const dispatch = useDispatch();
  const toast = customToast();

  const {newEmployeeData } = useSelector(
    (state) => state.manageEmployees
  );

  const { contactDetailKeys, contactDetails, isContactDetailsLoading } = useSelector(
    (state) => state.manageContacts
  );

  const [localContactDetails, setLocalContactDetails] = useState({});

  useEffect(() => {
    dispatch(fetchContactDetailKeys());
  }, [dispatch]);

  useEffect(() => {
    if (mode === 'Edit' && employeeId) {
      dispatch(fetchContactDetailsByEmployeeId(employeeId));
    } else if (mode === 'Add') {
      setLocalContactDetails({});
    }
  }, [dispatch, mode, employeeId]);

  useEffect(() => {
    if (contactDetails && mode === 'Edit') {
      setLocalContactDetails(contactDetails);
    }
  }, [contactDetails, mode]);


  const handleInputChange = (key, value) => {
    setLocalContactDetails((prevState) => ({
      ...prevState,
      [key]: value, 
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const empId = mode === 'Add' ? newEmployeeData?.employee_id : employeeId;

    if (!empId) {
      toast.showToast({
        title: 'Error',
        description: 'Employee ID is missing.',
        status: 'error',
      });
      return;
    }
    
    if (mode === 'Add') {
      dispatch(addContactDetails(empId, localContactDetails));
    } else {
      dispatch(editContactDetails(empId, localContactDetails));
    }
  };

  if (isContactDetailsLoading) {
    return  <Loader dims='mt-[18vh] mb-[18vh] mx-auto' />
  }

  return (
    <form onSubmit={handleSubmit} className="mx-4">
      <Flex wrap="wrap">
        {contactDetailKeys.map((keyItem) => (
          <FormControl isRequired key={keyItem.contact_detail_key_id} id={keyItem.key_name} mb={4} className="flex flex-col mx-1 mb-3 max-w-[49%]">
            <InputField
              label={keyItem.display_label}
              placeholder={`Enter ${keyItem.display_label}`}
              type={keyItem.key_type}
              value={localContactDetails[keyItem.contact_detail_key_id] || ''} 
              onChange={(e) => handleInputChange(keyItem.contact_detail_key_id, e.target.value)} 
              required
            />
          </FormControl>
        ))}
      </Flex>
      <button
        type="submit"
        className="float-right mb-3 mr-3 mt-2 rounded-md bg-brand-500 px-5 py-2 text-base font-medium text-white shadow-sm hover:bg-brand-600"
      >
        Save
      </button>
    </form>
  );
};

export default ContactInfoForm;
