import { FormLabel } from "@chakra-ui/react";
import Switch from "components/switch";

const SwitchField = (props) => {
  const { id, label, className, value, onChange, showLabel } = props;
  return (
    <div className={className}>
      <FormLabel
        htmlFor={id}
        className="!text-sm font-bold text-navy-700 dark:text-white"
      >
        {label}
      </FormLabel>
      <div className="ml-4 p-3 mt-2 flex items-center justify-start">
        {showLabel && <span className="text-sm text-navy-900 mr-4 dark:text-white">{value ? "Active" : "Inactive"}</span>}
        <Switch id={id} checked={value} value={value} onChange={onChange} />
      </div>
    </div>
  );
};

export default SwitchField;
