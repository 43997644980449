import Card from "components/card";
import { setAvgLeaveNotice } from "../reducers/myLeavesReducers";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { supabase } from "utils/supabase";
import { ImStatsDots } from "react-icons/im";

const getEmployeeAvgLeaveNotice = (employee_id) => async (dispatch) => {
  const { data, error } = await supabase
    .from('emp_avg_leave_notice')
    .select('*').eq('employee_id', employee_id)
  if (error) console.error({
    title: 'An error occurred.',
    description: error?.message && typeof error?.message === 'string' ? error?.message : 'Average value could not be fetched.',
    status: 'error',
  })
  else if (data && data?.length > 0) {
    dispatch(setAvgLeaveNotice(data[0]?.average_notice_period ?? 0));
  }
};

const AvgLeaveNoticeWidget = ({ employee_id }) => {
  const dispatch = useDispatch();
  const { avgLeaveNoticeInDays } = useSelector((state) => state.myLeaves);

  useEffect(() => {
    if (employee_id) dispatch(getEmployeeAvgLeaveNotice(employee_id))
  }, [employee_id, dispatch])

  return (
    <Card extra="!flex-row flex-grow items-center rounded-[20px]">
      <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
        <div className="rounded-full bg-lightPrimary p-3 dark:bg-navy-700">
          <span className="flex items-center text-brand-500 dark:text-brand-600">
            <ImStatsDots className="h-6 w-6" />
          </span>
        </div>
      </div>

      <div className="h-50 mx-4 flex w-auto flex-col justify-center">
        <p className="font-dm text-sm font-medium text-gray-600 capitalize">{"Avg. Leave Notice"}</p>
        <div className="flex justify-between items-center">
          <h4 className="text-xl font-bold text-navy-700 dark:text-white">
            {avgLeaveNoticeInDays}
          </h4>
        </div>
      </div>
    </Card>
  );
};

export default AvgLeaveNoticeWidget;