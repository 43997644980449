import React, { useEffect, useMemo } from "react";
import { Card } from "@chakra-ui/react";
import SearchInput from "components/search/Search";
import { MdAdd, MdDelete, MdEdit } from "react-icons/md";
import HolidayForm from "./components/AddHolidayForm";
import CustomAlertDialog from "components/alertDialog/AlertDialog";
import { useDispatch, useSelector } from 'react-redux';
import { closeDeleteConfirmation, openAddForm, openDeleteConfirmation, openEditForm, setSearch, setPage, closeForm, setSort } from "./reducers/manageAddHolidayReducers";
import { deleteHoliday, getAllHolidays } from "./api/manageAddHoliday";
import { PAGINATION_DEFAULT_PAGE_SIZE } from "variables/common";
import { formatDisplayDate } from "utils/dateUtils";
import CustomTable from "../tables/components/CustomTable";

const ManageHolidays = () => {
  const {
    isFormOpen, holidays, selectedHoliday, isDeleteConfirmationOpen, search, page, pagination, mode,
    isLoading, sortBy
  } = useSelector((state) => state.addHoliday);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllHolidays(search, page, sortBy));
    dispatch(closeForm());
  }, [dispatch, search, page, sortBy]);

  const handlePageChange = (newPage) => {
    dispatch(setPage(newPage + 1));
  };

  const handleSortChange = (newSortBy) => {
    dispatch(setSort(newSortBy));
  };

  const pageSize = PAGINATION_DEFAULT_PAGE_SIZE;
  const data = useMemo(() => holidays, [holidays]);
  const columns = useMemo(() => [
    {
      Header: 'Holiday Name',
      accessor: 'holiday_name',
    },
    {
      Header: 'Start Date',
      accessor: 'start_date',
      Cell: ({ value }) => (value ? formatDisplayDate(value) : value),
    },
    {
      Header: 'End Date',
      accessor: 'end_date',
      Cell: ({ value }) => (value ? formatDisplayDate(value) : value),
    },
    {
      Header: 'Actions',
      disableSortBy: true,
      Cell: ({ row }) => (
        <>
          <button
            onClick={() => dispatch(openEditForm(row.original))}
            className="text-xl mr-4 text-brand-500 hover:text-brand-600 active:text-brand-700 dark:text-white"
          >
            <MdEdit size={25} />
          </button>
          <button
            onClick={() => dispatch(openDeleteConfirmation(row.original))}
            className="text-xl text-red-500 hover:text-red-600 active:text-red-700 dark:text-white"
          >
            <MdDelete size={25} />
          </button>
        </>
      ),
    },
  ], [dispatch]);

  return (
    <>
      {isFormOpen && <HolidayForm mode={mode}
        data={selectedHoliday} />}
      <div className="my-6 ml-4">
        <Card className="bg-white dark:!bg-navy-800 px-4 pb-2 dark:shadow-none dark:text-lightPrimary ">
          <div className="flex mb-3 mx-[1rem] h-full items-center justify-between">
            <SearchInput
              value={search}
              onChange={(val) => dispatch(setSearch(val))}
              placeholder="Search by holiday name.."
            />
            {!isFormOpen && <button
              onClick={() => dispatch(openAddForm())}
              className="text-xl text-brand-500 hover:text-brand-600 active:text-brand-700 dark:text-brand-700"
            >
              <MdAdd size={35} />
            </button>}
          </div>
          <CustomTable
            columns={columns}
            data={data}
            isLoading={isLoading}
            pageIndex={page - 1}
            pageSize={pageSize}
            pageCount={Math.ceil(pagination.total / pageSize)}
            gotoPage={handlePageChange}
            canPreviousPage={page > 1}
            canNextPage={page * pageSize < pagination.total}
            totalItems={pagination.total}
            sortBy={sortBy}
            setSortBy={handleSortChange}
          />
        </Card>
      </div>
      <CustomAlertDialog
        isOpen={isDeleteConfirmationOpen}
        onClose={() => dispatch(closeDeleteConfirmation())}
        onConfirm={() => {
          if (selectedHoliday && selectedHoliday?.public_holiday_id) {
            dispatch(deleteHoliday(selectedHoliday.public_holiday_id, search, page, sortBy));
          }
        }}
        header="Confirm Delete"
        body="Are you sure you want to delete this holiday?"
        confirmText="Delete"
      />
    </>
  )
}

export default ManageHolidays;
