export const dateDiffInDays = (startDate, endDate, holidays = [], includeHolidays = false) => {
  if (!startDate && !endDate) return 0;
  if (!endDate) return 1;
  const formattedHolidayDates = holidays.map(holiday => new Date(holiday).toDateString());

  const start = new Date(startDate);
  const end = new Date(endDate);

  let days = 0;

  for (let current = start; current < end; current.setDate(current.getDate() + 1)) {
    if (includeHolidays) days++
    else {
      const dayOfWeek = current.getDay();
      // Exclude Saturdays (6) and Sundays (0)
      if (dayOfWeek !== 6 && dayOfWeek !== 0) {
        const currentDateStr = current.toDateString();
        if (!formattedHolidayDates.includes(currentDateStr)) {
          days++;
        }
      }
    }
  }

  // Include the end date if it's not a holiday and not a weekend
  if (!formattedHolidayDates.includes(end.toDateString()) && end.getDay() !== 6 && end.getDay() !== 0) {
    days++;
  }

  return days;
};

export const getDatesDifference = (startDate, endDate) => {
  if (!startDate || !endDate) return 0;
  // Check if both dates are the same
  if (new Date(startDate).toDateString() === new Date(endDate).toDateString()) return 0;
  const start = new Date(startDate).getTime();
  const end = new Date(endDate).getTime();
  // Calculate the difference in milliseconds
  const diffMs = end - start;
  // Convert milliseconds to days
  const diffDays = Math.ceil(diffMs / (1000 * 60 * 60 * 24));

  return diffDays;
};

export const formatDisplayDate = (inputDate) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const date = new Date(inputDate);
  // Extract the day, month, and year components
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear().toString().slice(-2); // Extract last two digits of the year
  const month = months[monthIndex];
  const formattedDate = `${day < 10 ? '0' : ''}${day}-${month}-${year}`;
  return formattedDate;
}

export const hasDateRangePassed = (startDate, endDate = null) => {
  const currentDate = new Date();
  const startDateTime = new Date(startDate);

  if (endDate === null) {
    // Check if only start date has passed
    return startDateTime < new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
  } else {
    const endDateTime = new Date(endDate);
    // Check if both start and end dates have passed
    return endDateTime < new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
  }
}

export const isFromDateGreaterThanTo = (start, end) => end ? new Date(start)
  .getTime() > new Date(end).getTime() : false;