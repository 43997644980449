import { setAssetDetails, setIsAssetDetailsLoading } from '../reducers/manageAssetsReducers';
import customToast from 'utils/toastUtils';
import { supabase } from 'utils/supabase';

const toast = customToast();

export const addAssetDetails = (employeeId, assetDetails) => async (dispatch) => {
  try {
    const { error } = await supabase
      .from('company_assets')
      .insert(
        assetDetails.map((asset) => ({
          employee_id: employeeId,
          asset_type: asset.asset_type,
          serial_number: asset.serial_number,
        }))
      )
      .select();

    if (error) {
      throw new Error(`Error adding asset details. ${error.message}`);
    }

    dispatch(setAssetDetails(assetDetails));
    toast.showToast({
      title: 'Success',
      description: 'Asset details added successfully.',
      status: 'success',
    });
  } catch (error) {
    toast.showToast({
      title: 'Error',
      description: 'Unable to add asset details.',
      status: 'error',
    });
  }
};

export const editAssetDetails = (employeeId, assetDetails) => async (dispatch) => {
  try {
    for (let asset of assetDetails) {
      if (!asset.asset_id) {
        const { error: insertError } = await supabase
          .from('company_assets')
          .insert({
            employee_id: employeeId,
            asset_type: asset.asset_type,
            serial_number: asset.serial_number,
          });

        if (insertError) {
          toast.showToast({
            title: 'Error',
            description: `Error adding new asset. ${insertError.message}`,
            status: 'Error',
          });
        }
      } else {
        const { error: updateError } = await supabase
          .from('company_assets')
          .update({
            asset_type: asset.asset_type,
            serial_number: asset.serial_number,
          })
          .eq('asset_id', asset.asset_id)
          .eq('employee_id', employeeId);

        if (updateError) {
          throw new Error(`Error updating asset details. ${updateError.message}`);
        }
      }
    }

    dispatch(setAssetDetails(assetDetails));
    toast.showToast({
      title: 'Success',
      description: 'Asset details updated successfully.',
      status: 'success',
    });
  } catch (error) {
    toast.showToast({
      title: 'Error',
      description: 'Unable to update or add asset details.',
      status: 'error',
    });
  }
};

export const fetchAssetDetailsByEmployeeId = (employeeId) => async (dispatch) => {
  try {
    dispatch(setIsAssetDetailsLoading(true));

    const { data, error } = await supabase
      .from('company_assets')
      .select('asset_id, asset_type, serial_number')
      .eq('employee_id', employeeId);

    if (error) {
      throw new Error(`Error fetching asset details. ${error.message}`);
    }

    dispatch(setAssetDetails(data));
    dispatch(setIsAssetDetailsLoading(false));
  } catch (error) {
    toast.showToast({
      title: 'Error',
      description: 'Unable to fetch asset details.',
      status: 'error',
    });
    dispatch(setIsAssetDetailsLoading(false));
  }
};
