import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  contactDetailKeys: [],
  contactDetails: {},
  isContactDetailsLoading: false,
};

const manageContactReducers = createSlice({
  name: 'manageContacts',
  initialState,
  reducers: {
    setContactDetailKeys: (state, action) => {
      state.contactDetailKeys = action.payload;
    },
    setContactDetails: (state, action) => {
      state.contactDetails = action.payload;
    },
    updateContactDetail: (state, action) => {
      const { key, value } = action.payload;
      state.contactDetails[key] = value;
    },
    setIsContactDetailsLoading: (state, action) => {
      state.isContactDetailsLoading = action.payload;
    },
  },
});

export const {
  setContactDetailKeys,
  setContactDetails,
  updateContactDetail,
  setIsContactDetailsLoading,
} = manageContactReducers.actions;

export default manageContactReducers.reducer;
