import { closeDeleteConfirmation, closeForm, setPaginationData, setLeaveRequests, setEmployeeLeaveQuota, setIsLoading } from '../reducers/myLeavesReducers';
import customToast from 'utils/toastUtils';
import { supabase } from 'utils/supabase';
import { PAGINATION_DEFAULT_PAGE_SIZE } from 'variables/common';
import { sendLeaveDeleteNotification } from './notification';
import { HR_APP_EMAIL } from 'variables/common';
import { LEAVE_ATTACHMENTS_STORAGE } from 'variables/common';

const toast = customToast();
const pageSize = PAGINATION_DEFAULT_PAGE_SIZE

export const getEmployeeLeaveQuota = (employee_id) => async (dispatch) => {
  const { data, error } = await supabase
    .from('employee_leave_summary')
    .select('*').eq('employee_id', employee_id)
  if (error) toast.showToast({
    title: 'An error occurred.',
    description: error?.message && typeof error?.message === 'string' ? error?.message : 'Leaves quota could not be fetched.',
    status: 'error',
  })
  else if (data && data?.length > 0) {
    dispatch(setEmployeeLeaveQuota(data[0]));
  }
};

export const getAllLeaveTypes = async (employee) => {
  try {
    const { data, error } = await supabase
      .from('leave_types')
      .select('*')
      .eq('leave_year', new Date().getFullYear()) // current year
      .eq('visibility_to_employee', 'TRUE')
      .order('display_order')
    if (error) toast.showToast({
      title: 'An error occurred.',
      description: error?.message && typeof error?.message === 'string' ? error?.message : 'Leave types data could not be fetched.',
      status: 'error',
    })
    else if (data) {
      let typesData = data;
      if (employee && employee?.employee_gender === "female") {
        typesData = data.filter(lv => lv.leave_type_name !== "paternity")
      } else if (employee && employee?.employee_gender === "male") {
        typesData = data.filter(lv => lv.leave_type_name !== "maternity")
      }
      return typesData
    }
    else return []
  } catch (error) {
    toast.showToast({
      title: 'An error occurred.',
      description: 'Leave types data could not be fetched.',
      status: 'error',
    })
    return []
  }
};

const getLeaveRequestsByEmployee = async (employee_id, search, page, sortBy) => {
  const { data, error } = search ? await supabase
    .from('leave_requests_view').select('*')
    .or(`start_date.eq.${search}, end_date.eq.${search}`)
    .eq('employee_id', employee_id)
    .order(sortBy[0].id, { ascending: !sortBy[0].desc })
    .range((page - 1) * pageSize, (pageSize * page) - 1) :
    await supabase.from('leave_requests_view')
      .select('*').eq('employee_id', employee_id)
      .order(sortBy[0].id, { ascending: !sortBy[0].desc })
      .range((page - 1) * pageSize, (pageSize * page) - 1)
  const { count, err } = search && search?.length > 0 ? await supabase
    .from('leave_requests_view')
    .select('*', { count: 'exact', head: true })
    .or(`start_date.eq.${search}, end_date.eq.${search}`)
    .eq('employee_id', employee_id) :
    await supabase
      .from('leave_requests_view')
      .select('*', { count: 'exact', head: true })
      .eq('employee_id', employee_id)
  if (err) toast.showToast({
    title: 'Count error.',
    description: err?.message && typeof err?.message === 'string' ? err?.message : 'Could not get total count of records.',
    status: 'error',
  })
  if (error) toast.showToast({
    title: 'An error occurred.',
    description: error?.message && typeof error?.message === 'string' ? error?.message : 'Leave data could not be fetched.',
    status: 'error',
  })
  return { data: data ?? [], count: count ?? data?.length }
}

export const getAllLeaveRequests = (employee_id, search, page, sortBy) => async (dispatch) => {
  try {
    dispatch(setIsLoading(true))
    const { data, count } = await getLeaveRequestsByEmployee(employee_id, search, page, sortBy)
    if (data) {
      dispatch(setLeaveRequests(data));
      dispatch(setPaginationData({
        total: count ?? data.length,
      }))
    }
    dispatch(setIsLoading(false))
  } catch (error) {
    toast.showToast({
      title: 'An error occurred.',
      description: 'Data could not be fetched.',
      status: 'error',
    })
  }
};

export const uploadAttachment = async (file, userId) => {
  let uploadedPath = ''
  const filename = `${new Date().getTime()}_${file.name}`
  const { data, error } = await supabase.storage.from(LEAVE_ATTACHMENTS_STORAGE).upload(`assets/${userId}/${filename}`, file)

  if (data) {
    uploadedPath = data.fullPath
  }
  else if (error) {
    toast.showToast({
      title: 'An error occurred.',
      description: 'Unable to upload file.',
      status: 'error',
    })
  }
  return uploadedPath
};

export const deleteAttachmentsFromStorage = async (files) => {
  const updated = files.map(file => file?.startsWith(`${LEAVE_ATTACHMENTS_STORAGE}/`) ? file.replace(`${LEAVE_ATTACHMENTS_STORAGE}/`, '') : file)
  const { error } = await supabase.storage.from(LEAVE_ATTACHMENTS_STORAGE).remove(updated)

  if (error) {
    toast.showToast({
      title: 'An error occurred.',
      description: 'Unable to remove file from storage.',
      status: 'error',
    })
  }
};

export const checkDuplicateLeave = async (leaveData, employee_id, leave_request_id) => {
  const { start_date, end_date } = leaveData;
  const endDate = end_date || start_date; // if end_date is null, use start_date as end_date

  const { data, err } = leave_request_id ? await supabase
    .from('leave_request')
    .select('leave_request_id')
    .eq('employee_id', employee_id)
    .neq('leave_status', 'deleted')
    .neq('leave_request_id', leave_request_id)
    .or(`and(start_date.lte.${endDate}, end_date.gte.${start_date})`) : await supabase
      .from('leave_request')
      .select('leave_request_id')
      .eq('employee_id', employee_id)
      .neq('leave_status', 'deleted')
      .or(`and(start_date.lte.${endDate}, end_date.gte.${start_date})`);

  if (err) {
    console.error('Error checking for duplicate leave:', err);
    toast.showToast({
      title: 'An error occurred.',
      description: err?.message && typeof err?.message === 'string' ? err?.message : 'Error checking for duplicate leave.',
      status: 'error',
    })
    throw err;
  }

  return data.length > 0; // Return true if there are any overlapping leave requests
};

export const addNewLeaveRequest = (leaveData, files, userId, search, page, sortBy) => async (dispatch) => {
  let isAdded = false;
  delete leaveData.files;
  leaveData.start_date = leaveData.start_date.toLocaleDateString("en-US");
  leaveData.end_date = leaveData.end_date ? leaveData.end_date.toLocaleDateString("en-US") : leaveData.start_date;
  const isDuplicate = await checkDuplicateLeave(leaveData, leaveData.employee_id);
  if (isDuplicate) {
    // Handle the case where a duplicate leave request is found
    toast.showToast({
      title: 'Could not add leave.',
      description: "Duplicate data. You have already entered a leave for the date(s)",
      status: 'error',
    })
    dispatch(closeForm());
    return;
  }
  const { data, error } = await supabase
    .from('leave_request')
    .insert([leaveData])
    .select();
  if (error) {
    let errorMsg = error?.message && typeof error?.message === 'string' ? error?.message : 'Unable to add leave request.'
    toast.showToast({
      title: 'An error occurred.',
      description: errorMsg,
      status: 'error',
    })
    dispatch(closeForm());
  }
  else if (data && data[0]?.leave_request_id) {
    const uploaded = [];
    if (files.length > 0) {
      for (let i = 0; i < files.length; i += 1) {
        const resp = await uploadAttachment(files[i], userId)
        if (resp && resp !== '') uploaded.push(resp)
      }
    }
    if (uploaded && uploaded.length > 0) {
      const { error: err } = await supabase.from('leave_request_attachments').insert(
        uploaded.map(file => ({ attachment_path: file, leave_request_id: data[0].leave_request_id }))
      ).select()
      if (err) toast.showToast({
        title: 'An error occurred.',
        description: err?.message && typeof err?.message === 'string' ? err?.message : 'Unable to add attachments.',
        status: 'error',
      })
    }
    dispatch(getAllLeaveRequests(leaveData.employee_id, search, page, sortBy));
    dispatch(getEmployeeLeaveQuota(leaveData.employee_id))
    dispatch(closeForm());
    isAdded = true
    toast.showToast({
      title: 'Added.',
      description: 'Leave added successfully',
      status: 'success',
    })
  }
  return isAdded
};

export const editLeaveRequest = (leaveData, files, prevFiles, userId, search, page, sortBy) => async (dispatch) => {
  leaveData.start_date = leaveData.start_date.toLocaleDateString("en-US");
  if (leaveData.is_half_day) {
    leaveData.end_date = leaveData.start_date
  } else {
    leaveData.end_date = leaveData.end_date ? leaveData.end_date.toLocaleDateString("en-US") : leaveData.start_date;
  }
  // Perform duplicate check
  const isDuplicate = await checkDuplicateLeave(leaveData, leaveData.employee_id, leaveData.leave_request_id);
  if (isDuplicate) {
    // Handle the case where a duplicate leave request is found
    toast.showToast({
      title: 'Could not add leave.',
      description: "Duplicate data. You have already entered a leave for the date(s)",
      status: 'error',
    })
    dispatch(closeForm());
    return;
  }
  const removedAttachments = []; // Track removed attachments

  // Check if files have changed
  const existingFileNames = prevFiles || [];
  const newFileNames = files.map((file) => file.name);

  // Find removed attachments
  existingFileNames.forEach((fileName) => {
    if (!newFileNames.includes(fileName)) {
      removedAttachments.push(fileName);
    }
  });

  delete leaveData.files;
  // Update leave request data
  const { data, error } = await supabase
    .from('leave_request')
    .update({ ...leaveData, updated_at: new Date().toLocaleString("en-US") })
    .eq('leave_request_id', leaveData.leave_request_id)
    .select();

  if (error) {
    let errorMsg = error?.message && typeof error?.message === 'string' ? error?.message : 'Unable to update leave request.'
    toast.showToast({
      title: 'An error occurred.',
      description: errorMsg,
      status: 'error',
    })
  } else if (data && data[0]?.leave_request_id) {
    // Upload new files
    const uploaded = [];
    const newFiles = files.filter(file => !file.name.startsWith(`${LEAVE_ATTACHMENTS_STORAGE}/`))
    if (newFiles.length > 0) {
      for (let i = 0; i < newFiles.length; i += 1) {
        const resp = await uploadAttachment(newFiles[i], userId);
        if (resp && resp !== '') uploaded.push(resp);
      }
    }

    // Delete removed attachments
    if (removedAttachments.length > 0) {
      await deleteAttachmentsFromStorage(removedAttachments)
      await supabase
        .from('leave_request_attachments')
        .delete()
        .in('attachment_path', removedAttachments);
    }

    // Insert new attachments
    if (uploaded.length > 0) {
      const { error: err } = await supabase
        .from('leave_request_attachments')
        .insert(
          uploaded.map((file) => ({
            attachment_path: file,
            leave_request_id: leaveData.leave_request_id,
          }))
        )
        .select();

      if (err) {
        toast.showToast({
          title: 'An error occurred.',
          description: err?.message && typeof err?.message === 'string' ? err?.message : 'Unable to add attachments.',
          status: 'error',
        });
      }
    }

    dispatch(getAllLeaveRequests(leaveData.employee_id, search, page, sortBy));
    dispatch(getEmployeeLeaveQuota(leaveData.employee_id))
    dispatch(closeForm());
    toast.showToast({
      title: 'Updated.',
      description: 'Leave updated successfully',
      status: 'success',
    });
  }
};

export const deleteLeaveRequest = (leaveReq, employee, search, page, leaveTypes, sortBy) => async (dispatch) => {
  const { error } = await supabase
    .from('leave_request')
    .update({ 'leave_status': 'deleted' })
    .eq('leave_request_id', leaveReq.leave_request_id)
  if (error) {
    toast.showToast({
      title: 'Error',
      description: error?.message && typeof error?.message === 'string' ? error?.message : 'Unable to delete leave.',
      status: 'error',
    })
    dispatch(closeDeleteConfirmation())
  } else {
    // if (files && files?.length > 0) await deleteAttachmentsFromStorage(files)
    dispatch(getAllLeaveRequests(employee.employee_id, search, page, sortBy));
    dispatch(getEmployeeLeaveQuota(employee.employee_id));
    dispatch(closeDeleteConfirmation());
    await sendLeaveDeleteNotification(employee, leaveReq, leaveTypes);
    toast.showToast({
      title: 'Deleted.',
      description: 'Leave deleted successfully',
      status: 'success',
    })
  }
};

export const downloadAttachmentsFromStorage = async (file) => {
  try {
    const filename = file?.startsWith(`${LEAVE_ATTACHMENTS_STORAGE}/`) ? file.replace(`${LEAVE_ATTACHMENTS_STORAGE}/`, '') : file
    const { data, error } = await supabase.storage
      .from(LEAVE_ATTACHMENTS_STORAGE)
      .createSignedUrl(filename, 3600)

    if (data) {
      window.open(data.signedUrl, '_blank');
    }

    if (error) {
      toast.showToast({
        title: 'An error occurred.',
        description: 'Unable to get file from storage.',
        status: 'error',
      })
    }
  } catch (e) {
    console.log(e)
    toast.showToast({
      title: 'An error occurred.',
      description: 'Unable to get file from storage.',
      status: 'error',
    })
  }
};

export const calculateRemainingLeaves = (leavesData, leaveType) => {
  const total_leaves = leavesData[`${(leaveType === "paternity" || leaveType === "maternity") ? 'parental' : leaveType}_total`]
  const availed_leaves = leavesData[`${(leaveType === "paternity" || leaveType === "maternity") ? 'parental' : leaveType}_availed`]
  let remaining = total_leaves - availed_leaves
  if (leaveType === "annual") {
    remaining = leavesData.annual_total - (leavesData.annual_availed - Math.min(leavesData.carry_forward_availed, leavesData.carry_forward_total))
  }
  return remaining
}

export const getEmployeeReportingLine = async (employee)  => {
  // Fetch the employee's reporting line from the department_reporting table
  let managerIds = []
  const { data: managers } = await supabase.from('department_reporting')
    .select("reporting_employee_id").eq('department_id', employee.department_id)
  if (managers && managers?.length > 0) {
    managerIds.push(managers.map(emp => emp.reporting_employee_id))
  }
  const { data, error } = await supabase
    .from('employees_view')
    .select('*').or(`employee_id.in.(${managerIds}), employee_email.eq.${HR_APP_EMAIL}`)

  if (error) {
    toast.showToast({
      title: 'Error.',
      description: "Could not fetch employee's reporting line",
      status: 'error',
    });
    return undefined;
  } else {
    return data
  }
};

export const addLeaveEventToCalendar = (startDate, endDate, halfDay, firstHalf, createOutOfOfficeEvent) => {
  // Helper function to convert 'MM/DD/YYYY' to 'YYYY-MM-DD'
  const formatDate = (dateString) => {
    const [month, day, year] = dateString.split('/');
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  };
  const start = formatDate(startDate); 
  const end = formatDate(endDate);
  let startDateTime, endDateTime;
  if (halfDay) {
    if (firstHalf) {
      // First half of the day
      startDateTime = new Date(`${start}T09:00:00`);
      endDateTime = new Date(`${start}T14:00:00`);
    } else {
      // Second half of the day
      startDateTime = new Date(`${start}T13:00:00`);
      endDateTime = new Date(`${start}T18:00:00`);
    }
  } else if (startDate === endDate) {
    // Full-day leave (whole day off)
    startDateTime = new Date(`${start}T09:00:00`);
    endDateTime = new Date(`${end}T23:59:59`);
  } else {
    // Multiple-day leave
    startDateTime = new Date(`${start}T00:00:00`);
    endDateTime = new Date(`${end}T23:59:59`);
  }
  createOutOfOfficeEvent(startDateTime, endDateTime);
}