import { formatDisplayDate } from "utils/dateUtils";
import { sendEmail } from "utils/sendgrid";
import { supabase } from "utils/supabase";
import { dateDiffInDays } from "utils/dateUtils";
import customToast from "utils/toastUtils";
import { HR_APP_EMAIL } from "variables/common";
import { HR_APP_MAIL_NAME } from "variables/common";
import { HR_APP_URL } from "variables/common";
import { DEV_SUPABASE_URL } from "variables/common";
import { LOCAL_DEV_EMAIL } from "variables/common";
import { getDatesDifference } from "utils/dateUtils";

const toast = customToast();
const LOGO_URL = `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/public_assets/logo/logo-png.png`
const generateEmailHtmlTemplate = (message, recipient) =>
  `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width">
    <title>Message from HR App</title>
    <style>
        body {
            font-family: Arial, sans-serif;
            line-height: 1.6;
            background-color: #f4f4f4;
            margin: 0;
            padding: 0;
        }
        .container {
            background-color: #ffffff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }
        .footer {
            margin: 20px 0;
            padding: 20px;
            font-size: 0.9em;
            color: #777777;
            background-color: #eeebeb;
        }
         .footer .img-container {
            padding: 0;
            margin: 0;
        }   
        .footer img {
            width: auto;
            height: 80px;
        }
        .footer a {
            color: #E07417;
            text-decoration: none;
        }
        .additional-message {
            font-size: 1em;
            color: #555555;
            margin: 10px;
        }
        .additional-message .msg {
            margin-left: 10px;
        }
        .message-header {
            margin-bottom: 10px;
            padding-top: 8px;
        }    
    </style>
</head>
<body>
    <div class="container">
        <div class="content">
            <p><strong>Dear ${recipient}</strong>,</p>
            <p>${message}</p>
            <p>Thank you for your attention.</p>
        </div>
        <div class="footer">
            <p>For more info, please visit: <a href="${HR_APP_URL}">HR App</a></p>
            <p class="img-container">
                <a href="${HR_APP_URL}"><img src="${LOGO_URL}" alt="Logo"></a>
            </p>
        </div>
    </div>
</body>
</html>`

const LEAVE_EMAIL_SUBJECT = 'Leave Notification: [Employee_Name] - [Start_Date]';
const LEAVE_EMAIL_CONTENT = `This is to notify you that <b>[Employee_Name]</b> has applied for <b>[Leave_Duration] [Leave_Type]</b> from <b>[Start_Date] to [End_Date]</b>.
<br> Leave notice period: <b>[Leave_Notice]</b><br> 
<br> Please contact [Employee_Email] in case of urgent queries.<b>[Additional_message]</b>`;
const HALF_DAY_LEAVE_EMAIL_CONTENT = `This is to notify you that <b>[Employee_Name]</b> has applied for a <b>half-day leave ([Half_leave_type])</b> on <b>[Start_Date]</b>. 
<br> Leave notice period: <b>[Leave_Notice]</b><br> 
<br> Please contact [Employee_Email] in case of urgent queries.<b>[Additional_message]</b>`;

const getEmailContentForLeave = (empName, empEmail, leaveData, leaveTypes, holidays, notifymessage) => {
  let subject = LEAVE_EMAIL_SUBJECT.replace('[Employee_Name]', empName)
    .replace('[Start_Date]', formatDisplayDate(leaveData.start_date))
  const leaveNoticePeriod = getDatesDifference(new Date(), leaveData.start_date)
  if(leaveData.is_half_day) {
    subject = subject.concat(` - Half Day (${leaveData.half_leave_type ?? "first-half"})`)
  }
  let body = leaveData.is_half_day ? HALF_DAY_LEAVE_EMAIL_CONTENT : LEAVE_EMAIL_CONTENT
  const leaveTypeName = leaveTypes.filter(lv => lv.leave_type_id === Number(leaveData.leave_type_id))[0]?.leave_type_name
  const duration = leaveData.leave_type_id && leaveTypeName === "maternity" ?
    dateDiffInDays(leaveData.start_date, leaveData.end_date, [], true) :
    dateDiffInDays(leaveData.start_date, leaveData.end_date, holidays)
  body = body.replace('[Employee_Name]', empName)
    .replace('[Employee_Email]', empEmail)
    .replace('[Leave_Duration]', duration)
    .replace('[Leave_Type]', `${leaveTypeName} ${duration === 1 ? 'leave' : 'leaves'}`)
    .replace('[Additional_message]', notifymessage ? `<div class="additional-message"><p class="message-header">Message from ${empName}:</p><p class="msg">${notifymessage}</p></div>` : '')
    .replace('[App_URL]', HR_APP_URL)
    .replace('[Half_leave_type]', leaveData.half_leave_type ?? "first-half")
    .replace('[Leave_Notice]', `${leaveNoticePeriod} ${leaveNoticePeriod > 1 ? "days" : "day"}`)
  if (duration > 1) {
    subject = subject.concat(` to ${formatDisplayDate(leaveData.end_date)}`)
    body = body.replace('[Start_Date]', formatDisplayDate(leaveData.start_date))
      .replace('[End_Date]', formatDisplayDate(leaveData.end_date))
  } else {
    body = body.replace('from <b>[Start_Date]', `on <b>${formatDisplayDate(leaveData.start_date)}`)
      .replace('to [End_Date]', '').replace('[Start_Date]', formatDisplayDate(leaveData.start_date))
  }

  return { subject, body }
}

export const sendEmailNotification = async (employee, leaveData, leaveTypes, holidays, notifyemployee, notifymessage) => {
  const { data, error } = await supabase
    .from('employees_view')
    .select('*').in('employee_id', notifyemployee ?? [])
  if (error) {
    toast.showToast({
      title: 'Email not sent',
      description: `Could not send email notification`,
      status: 'warning',
    })
  }
  if (data && data?.length > 0) {
    const { subject, body } = getEmailContentForLeave(employee.employee_name, employee.employee_email, leaveData, leaveTypes, holidays, notifymessage);
    if (process.env.REACT_APP_SUPABASE_URL === DEV_SUPABASE_URL) {
      const emailHtml = generateEmailHtmlTemplate(body, data[0].employee_name)
      console.log({ emailRecipients: data.map(emp => emp.employee_email) })
      // send email notification to the local developer only in dev mode
      if (LOCAL_DEV_EMAIL) await sendEmail(LOCAL_DEV_EMAIL, data[0].employee_name, subject, emailHtml)
    }
    else {
      await Promise.all(data.map(async (emp) => {      
        const emailHtml = generateEmailHtmlTemplate(body, emp.employee_name)
        console.log({ emailRecipients: emp.employee_email })
        await sendEmail(emp.employee_email, emp.employee_name, subject, emailHtml)
      }))
    }
  }
}

export const sendLeaveDeleteNotification = async (employee, leaveRequest, leaveTypes) => {
  try {
    const EMAIL_SUBJECT = 'Leave Deletion Notification: [Employee_Name] - [Start_Date]'
    const EMAIL_CONTENT = `This is to notify that <b>[Employee_Name]</b> has deleted [Pronoun] <b>[Leave_Duration] [Leave_Type]</b> from <b>[Start_Date] to [End_Date]</b>. <br> <br> Please contact [Employee_Email] in case of urgent queries.`
    const HALF_DAY_LEAVE_EMAIL = `This is to notify that <b>[Employee_Name]</b> has deleted [Pronoun] <b>half-day leave</b> on <b>[Start_Date]</b>.<br> <br> Please contact [Employee_Email] in case of urgent queries.`
    let subject = EMAIL_SUBJECT.replace('[Employee_Name]', employee.employee_name)
      .replace('[Start_Date]', formatDisplayDate(leaveRequest.start_date))
    let body = leaveRequest.is_half_day ? HALF_DAY_LEAVE_EMAIL : EMAIL_CONTENT
    const leaveTypeName = leaveTypes.filter(lv => lv.leave_type_id === Number(leaveRequest.leave_type_id))[0]?.leave_type_name
    body = body.replace('[Employee_Name]', employee.employee_name)
      .replace('[Employee_Email]', employee.employee_email)
      .replace('[Leave_Duration]', leaveRequest.leave_duration)
      .replace('[Leave_Type]', `${leaveTypeName} ${leaveRequest.leave_duration === 1 ? 'leave' : 'leaves'}`)
      .replace('[Pronoun]', employee.employee_gender === 'male' ? 'his' : 'her')
      .replace('[App_URL]', HR_APP_URL)
    if (leaveRequest.leave_duration > 1) {
      subject = subject.concat(` to ${formatDisplayDate(leaveRequest.end_date)}`)
      body = body.replace('[Start_Date]', formatDisplayDate(leaveRequest.start_date))
        .replace('[End_Date]', formatDisplayDate(leaveRequest.end_date))
    } else {
      body = body.replace('from', 'on').replace('[Start_Date]', `${formatDisplayDate(leaveRequest.start_date)}`)
        .replace('to [End_Date]', '').replace('[Start_Date]', formatDisplayDate(leaveRequest.start_date))
    }
    const emailHtml = generateEmailHtmlTemplate(body, HR_APP_MAIL_NAME);
    if (process.env.REACT_APP_SUPABASE_URL === DEV_SUPABASE_URL) {
      // send email to local dev
      if (LOCAL_DEV_EMAIL) await sendEmail(LOCAL_DEV_EMAIL, HR_APP_MAIL_NAME, subject, emailHtml)
    }
    else {
      await sendEmail(HR_APP_EMAIL, HR_APP_MAIL_NAME, subject, emailHtml)
    }
  } catch (e) {
    console.log("Could not send leave delete notification", e)
  }
}
