import React from "react";
import { FormLabel } from '@chakra-ui/react';

const SelectField = (props) => {
  const {
    label,
    id,
    extra,
    options = [],
    required,
    placeholder,
    variant,
    state,
    disabled,
    value,
    onChange,
    capitalize = true,
  } = props;

  return (
    <div className={`${extra}`}>
      <FormLabel
        htmlFor={id}
        className={`!text-sm text-navy-700 dark:text-white ${variant === "auth" ? "ml-1.5 font-medium" : "font-bold"
          }`}
      >
        {label}
      </FormLabel>
      <select
        disabled={disabled}
        id={id}
        value={value}
        onChange={onChange}
        required={required}
        placeholder={placeholder}
        className={`mt-2 ${capitalize ? 'capitalize' : ''} flex h-12 w-full items-center rounded-xl border bg-white/0 p-3 text-sm outline-none ${disabled === true
          ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
          : state === "error"
            ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
            : state === "success"
              ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
              : "border-gray-200 dark:!border-white/10 dark:text-white"
          }`}
      >

        {placeholder && <option key='default' className="dark:text-white dark:bg-navy-800" value=''>{placeholder}</option>}

        {options.map((opt) => (
          <option key={opt.id} value={opt.id} className={`${capitalize ? 'capitalize' : ''} dark:text-white dark:bg-navy-800`}>
            {opt.value}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectField;
