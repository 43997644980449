import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  bankDetails: {
    bank_title: '',
    bank_name: '',
    account_number: '',
    iban_number: '',
    employee_status: true,
  },
  isBankDetailsLoading: false,
};

const manageBankReducers = createSlice({
  name: 'manageBank',
  initialState,
  reducers: {
    setBankDetails: (state, action) => {
      state.bankDetails = action.payload;
    },
    setIsBankDetailsLoading: (state, action) => {
      state.isBankDetailsLoading = action.payload;
    },
  },
});

export const {
  setBankDetails,
  setIsBankDetailsLoading,
} = manageBankReducers.actions;

export default manageBankReducers.reducer;
